import axios from 'axios';

import { showToast } from '@/components/common/ToastContainer';
import { removeAllToasts } from '@/components/common/ToastContainer/ToastContainer';

import { fileDownload } from '@/pages/portal/products/utils';

export function getThumbnailProps(thumbnail) {
  return {
    thumbnail: {
      alt: 'thumbnail',
      mobileImgData: {
        src: thumbnail
      }
    }
  };
}

export function isPdfFile(link) {
  return link?.split('.').includes('pdf');
}

export function getFileExtension(link) {
  return link?.split('.').pop().toUpperCase();
}

export function download(t, url, filename = 'download') {
  try {
    showToast({
      text: t('preparing-file-for-download'),
      dismissOnClick: true
    });
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, filename);
        removeAllToasts();
        showToast({ text: t('downloaded-successfully'), type: 'success' });
      });
  } catch (e) {
    showToast({ text: e, type: 'error' });
  }
}

export const downloadFile = (t, filePath, fileName = 'download') => {
  try {
    showToast({
      text: t('preparing-file-for-download'),
      dismissOnClick: true
    });
    const element = document.createElement('a');
    element.href = filePath;
    element.download = fileName;
    element.target = '_blank';
    element.rel = 'noopener noreferrer';
    document.body.appendChild(element);
    element.click();
    removeAllToasts();
    showToast({ text: t('downloaded-successfully'), type: 'success' });
    document.body.removeChild(element);
  } catch (error) {
    showToast({ text: error, type: 'error' });
  }
};

export function getFileFormat(link) {
  return link?.split('/').pop();
}

export function formatSize(bytes, decimals = 0) {
  if (!+bytes) return '';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
