import React from 'react';
import { useEffect, useRef, useState } from 'react';

import { t } from '@/utility/localization';

import Editor from '@/components/common/Editor/Editor';
import Icon from '@/components/npl/Icon';

const ChallengeCardContent = ({
  content,
  contentHTML = '',
  title,
  icon,
  trackReadMoreBtnClick,
  type
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    // Reason why we need resizeObserver is because image is loaded
    // at a later time, this guarantees that it observes and check
    // for difference in height
    const resizeObserver = new ResizeObserver(() => {
      // callback function whenever observed element changes in size
      // check if its overflowing
      if (
        contentRef.current &&
        contentRef.current.scrollHeight > contentRef.current.clientHeight
      ) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    // stop observing when component unmounts or when effect is rerun
    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <div className="mx-auto flex w-full flex-col justify-between gap-x-80 gap-y-24 border-t border-npl-neutral-light-solid-4 py-40 md:flex-row">
      <div className="flex max-w-[240px] flex-1 gap-x-12">
        <Icon
          name={icon}
          width={24}
          height={24}
          className="fill-npl-yellow-light-solid-11"
        />
        <p className="text-heading-sm font-semibold text-npl-text-icon-on-light-surface-primary">
          {title}
        </p>
      </div>
      <div className="w-full flex-1">
        {!expanded && (content || contentHTML) ? (
          <div className="truncated">
            <div
              className="relative max-h-[280px] overflow-hidden"
              ref={contentRef}>
              <Editor
                contentHTML={contentHTML}
                existingContent={content}
                editable={false}
                customContentClasses="w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
              />

              {isOverflowing && (
                <div className="bg-gradient-to-b absolute bottom-0 left-0 h-120 w-full from-transparent to-white-default"></div>
              )}
            </div>
            {isOverflowing && (
              <div className="mt-14 flex justify-center">
                <button
                  className="flex w-full items-center justify-end space-x-6 text-button-md font-medium text-dark-1b"
                  onClick={() => {
                    trackReadMoreBtnClick(type);
                    setExpanded(true);
                  }}>
                  <p className="text-npl-yellow-light-solid-11">
                    {t('see-more')}
                  </p>
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="full">
            <Editor
              existingContent={content}
              editable={false}
              customContentClasses="w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
            />
            {isOverflowing && (
              <button
                className="mt-14 flex w-full items-center justify-end space-x-6 text-button-md font-medium text-dark-1b"
                onClick={() => setExpanded(false)}>
                <p className="text-npl-yellow-light-solid-11">
                  {t('see-less')}
                </p>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeCardContent;
