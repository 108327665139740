import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-InfoCircleIcon';
const InfoCircleIcon = ({
  width = 20,
  height = 20,
  fill = '#75777A',
  customClassNames = ''
}) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20">
      <path
        d="M10 0.00012207C4.48 0.00012207 0 4.48012 0 10.0001C0 15.5201 4.48 20.0001 10 20.0001C15.52 20.0001 20 15.5201 20 10.0001C20 4.48012 15.52 0.00012207 10 0.00012207ZM10 11.0001C9.45 11.0001 9 10.5501 9 10.0001V6.00012C9 5.45012 9.45 5.00012 10 5.00012C10.55 5.00012 11 5.45012 11 6.00012V10.0001C11 10.5501 10.55 11.0001 10 11.0001ZM11 15.0001H9V13.0001H11V15.0001Z"
        fill={fill}
      />
    </svg>
  );
};

InfoCircleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};

export default InfoCircleIcon;
