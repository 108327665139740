import { useState } from 'react';

import {
  getCheckpoint,
  getUserCheckpointData
} from '@/services/community/CommunityChallengesService';
import { getAxiosErrorMsg } from '@/services/helpers';

import { showErrorToast } from '@/components/common/ToastContainer';

function useCheckpointData({ communityId, challengeId, participantId }) {
  const [loadingCheckpoint, setLoadingCheckpoint] = useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);

  const getParticipantCheckpointData = async (checkpointId) => {
    setLoadingCheckpoint(true);
    const { data, error } = participantId
      ? await getUserCheckpointData({
          communityId: communityId,
          challengeId: challengeId,
          participantId: participantId,
          checkpointId: checkpointId
        })
      : await getCheckpoint({
          communityId,
          challengeId,
          checkpointId
        });

    if (error) {
      const errorMessage = getAxiosErrorMsg(error);
      showErrorToast(errorMessage);
      setLoadingCheckpoint(false);

      return { data: null, error: errorMessage };
    }

    const sanitizedData = participantId
      ? data
      : {
          data: {
            checkpointData: data?.data
          }
        };

    setLoadingCheckpoint(false);

    return { data: sanitizedData, error: null };
  };

  return {
    getParticipantCheckpointData,
    loadingCheckpoint,
    setSelectedCheckpoint,
    selectedCheckpoint
  };
}

export default useCheckpointData;
