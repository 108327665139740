import React from 'react';
import { createPortal } from 'react-dom';

import { t } from '@/utility/localization';
import { getFilenameAndExtensionFromLink } from '@/utility/stringHelper';

import AdaptiveVideo from '@/components/common/AdaptiveVideo';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import useEscapeClick from '@/hooks/useEscapeClick';

const VideoSubmissionPreview = ({
  videoLink,
  videoThumbnail,
  onCopyClick,
  onDownloadClick,
  onClose
}) => {
  useEscapeClick(onClose);

  const { filename } = getFilenameAndExtensionFromLink(videoLink);

  if (typeof window === 'undefined') return null;

  const adaptiveVideoData = {
    isTrailerPlaying: true,
    mobileVideoOrientation: 'square',
    lgVideoOrientation: 'square',
    videoAssets: {
      mp4: videoLink
    }
  };

  return createPortal(
    <div className="z-over-intercom absolute">
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md" />
      <div className="h-full-dvh fixed inset-0 flex w-screen flex-col">
        {/* Nav */}
        <div className="min-w-0 flex justify-between space-x-16 px-24 py-16">
          <div className="min-w-0 flex items-center space-x-8 truncate">
            <NPLButton
              multiply
              rounded
              darkMode
              leadIcon="arrow-left"
              onClick={onClose}
            />
            {videoThumbnail ? (
              <img
                src={videoThumbnail}
                alt="preview-thumbnail"
                className="h-40 w-40 min-w-40 rounded-8 object-cover"
              />
            ) : (
              <div className="flex h-40 w-40 min-w-40 items-center justify-center rounded-8 border-1 border-npl-neutral-light-solid-6 bg-white-default">
                <Icon
                  name="video-recorder"
                  className="fill-npl-pink-light-11"
                  width={18}
                  height={18}
                />
              </div>
            )}
            <p className="truncate text-label-md font-medium text-npl-text-primary-on-dark">
              {filename}
            </p>
          </div>
          <div className="flex items-center space-x-6">
            <NPLButton
              multiply
              darkMode
              size="sm"
              hierarchy="outline"
              buttonText={t('download')}
              leadIcon="download-01"
              onClick={onDownloadClick}
            />
            <NPLButton
              multiply
              darkMode
              size="sm"
              hierarchy="outline"
              buttonText={t('copy-link')}
              leadIcon="copy"
              onClick={onCopyClick}
            />
          </div>
        </div>
        <div className="my-40 flex h-full justify-center">
          <AdaptiveVideo {...adaptiveVideoData} />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default VideoSubmissionPreview;
