import { useEffect } from 'react';

const useEscapeClick = (onEscape) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        // keyCode 27 corresponds to the escape key
        onEscape();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('keydown', handleEsc);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onEscape]);
};

export default useEscapeClick;
