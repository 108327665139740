import { FC, useEffect, useState } from 'react';

import withComponentClassName from '@/utility/withComponentClassName';

import {
  getCheckpointsSWR,
  getParticipantCheckpoints
} from '@/services/community/CommunityChallengesService';

import InfiniteList from '@/components/common/InfiniteList';
import SideDrawer from '@/components/common/SideDrawer';
import { showErrorToast } from '@/components/common/ToastContainer';

import useQueryParams from '@/hooks/useQueryParams';

import ChallengeCheckpointPage from '../ChallengeCheckpointPage';
import useCheckpointData from '../hooks/useCheckpointData';
import {
  CheckpointItemType,
  CheckpointType,
  CommunityInfoType,
  CurrentCheckpointDataType,
  MappedCheckpointItem
} from '../types';
import { checkIfCheckpointIsCompletedByStatus } from '../utils';
import CheckpointPreviewCard, {
  CheckpointPreviewCardSkeleton
} from './CheckpointPreviewCard';

export const DEFAULT_CHECKPOINT_PAGE_NO = 1;
export const CHECKPOINT_PAGE_SIZE = 20;

type CheckpointListDisplayProps = {
  challengeId: string;
  checkpointCount: number;
  communityId: string;
  communityInfo: CommunityInfoType;
  completeCheckpoint?: (
    updatedCheckpoint: CurrentCheckpointDataType
  ) => void;
  currentCheckpoint?: CurrentCheckpointDataType;
  enableCheckpointSubmissionAfterDeadline?: boolean;
  hasChallengeEnded?: boolean;
  hasManagerAccess?: boolean;
  isChallengeLocked?: boolean;
  isFeedEnabled?: boolean;
  isLeaderboardEnabled?: boolean;
  onCompleteFeed?: () => void;
  participantCheckpoints?: CheckpointItemType[];
  participantId?: string;
  participantJoinedDate?: string;
  stepByStepUnlocking?: boolean;
};

const CheckpointListDisplay: FC<CheckpointListDisplayProps> = ({
  checkpointCount,
  challengeId,
  communityId,
  participantId,
  stepByStepUnlocking = true,
  currentCheckpoint,
  participantCheckpoints,
  isChallengeLocked,
  isLeaderboardEnabled,
  hasManagerAccess,
  completeCheckpoint,
  isFeedEnabled,
  hasChallengeEnded,
  enableCheckpointSubmissionAfterDeadline,
  onCompleteFeed,
  participantJoinedDate
}) => {
  const [pageNo, setPageNo] = useState<number>(DEFAULT_CHECKPOINT_PAGE_NO);
  const [checkpoints, setCheckpoints] = useState<MappedCheckpointItem[]>(
    []
  );
  const { currentRouterQuery } = useQueryParams();
  const {
    getParticipantCheckpointData,
    setSelectedCheckpoint,
    selectedCheckpoint
  } = useCheckpointData({
    communityId,
    challengeId,
    participantId
  });
  const [isLoadingCheckpoints, setIsLoadingCheckpoints] = useState(false);

  //legacy logic - deprecate after the release
  const fetchCheckpoints = async (updatedPageNo: number) => {
    setIsLoadingCheckpoints(true);
    const { data, error } = await getCheckpointsSWR({
      challengeId,
      communityId,
      pageNo: updatedPageNo,
      pageSize: CHECKPOINT_PAGE_SIZE
    });
    setIsLoadingCheckpoints(false);

    if (error) {
      showErrorToast(error);
      return;
    }

    const mappedData = data.map((item: CheckpointType) => {
      const participantCheckpoint =
        participantCheckpoints?.find(
          (checkpoint) => checkpoint.itemObjectId === item._id
        ) || {};
      return {
        ...participantCheckpoint,
        ...item
      };
    });

    setCheckpoints((prevCheckpoints) => [
      ...prevCheckpoints,
      ...mappedData
    ]);
  };

  //new logic to fetch participant checkpoints with statuses
  const fetchParticipantCheckpoints = async (updatedPageNo: number) => {
    setIsLoadingCheckpoints(true);
    const { data, error } = await getParticipantCheckpoints({
      challengeId,
      communityId,
      pageNo: updatedPageNo,
      pageSize: CHECKPOINT_PAGE_SIZE
    });

    if (error) {
      showErrorToast(error);
      setIsLoadingCheckpoints(false);
      return;
    }

    const { checkpoints } = data?.data ?? {};

    setCheckpoints((prevCheckpoints) => [
      ...prevCheckpoints,
      ...checkpoints
    ]);
    setIsLoadingCheckpoints(false);
  };

  const updateCompletionStatusWithUpdatedCheckpoint = (
    updatedCheckpoint: CurrentCheckpointDataType
  ) => {
    setCheckpoints((prevCheckpoints) =>
      prevCheckpoints.map((checkpoint) =>
        checkpoint._id === updatedCheckpoint.itemObjectId
          ? {
              ...checkpoint,
              completionStatus: updatedCheckpoint.completionStatus
            }
          : checkpoint
      )
    );
  };

  const openCheckpointDetails = async (checkpointId: string) => {
    const { data } = await getParticipantCheckpointData(checkpointId);

    const checkpointData = data?.data;

    setSelectedCheckpoint(checkpointData);
  };

  useEffect(() => {
    if (currentRouterQuery?.checkpointId) {
      openCheckpointDetails(currentRouterQuery?.checkpointId as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouterQuery?.checkpointId]);

  const renderedCheckpoints = checkpoints.map(
    (checkpoint: MappedCheckpointItem) => {
      const isFutureCheckpoint =
        new Date(checkpoint.startTime) > new Date();

      const isCurrentParticipantCheckpoint =
        currentCheckpoint?._id === checkpoint._id;

      const isCompleted = checkIfCheckpointIsCompletedByStatus(
        checkpoint.completionStatus
      );

      const isCurrentCheckpointCompleted =
        checkIfCheckpointIsCompletedByStatus(
          currentCheckpoint?.completionStatus
        );

      const isNextCheckpoint =
        currentCheckpoint?.startTime &&
        new Date(checkpoint.startTime) >
          new Date(currentCheckpoint.startTime);

      let locked =
        !participantId || //CM access
        (stepByStepUnlocking &&
          !isCurrentCheckpointCompleted &&
          isNextCheckpoint) || //if the current checkpoint is not completed, then the next checkpoint is locked
        isChallengeLocked || //if the challenge is locked, then all checkpoints are locked
        isFutureCheckpoint; //future checkpoints are locked

      //override locked status if the checkpoint is the current participant checkpoint
      if (Object.hasOwn(checkpoint, 'locked')) {
        locked = checkpoint.locked;
      }

      let completionStatus =
        currentCheckpoint?.itemObjectId === checkpoint._id
          ? currentCheckpoint.completionStatus
          : null;

      if (Object.hasOwn(checkpoint, 'completionStatus')) {
        completionStatus = checkpoint.completionStatus;
      }

      return {
        ...checkpoint,
        isCurrentParticipantCheckpoint,
        isCompleted,
        locked,
        completionStatus
      };
    }
  );

  const lastCheckpointIndex = renderedCheckpoints.length - 1;

  return (
    <div className="relative">
      <InfiniteList
        items={renderedCheckpoints}
        hasMore={checkpoints?.length < checkpointCount}
        isLoading={isLoadingCheckpoints}
        renderSkeleton={() => <CheckpointPreviewCardSkeleton />}
        loadMore={() => {
          participantId
            ? fetchParticipantCheckpoints(pageNo)
            : fetchCheckpoints(pageNo);

          setPageNo(pageNo + 1);
        }}>
        {(checkpoint: MappedCheckpointItem, index: number) => (
          <CheckpointPreviewCard
            checkpoint={checkpoint}
            key={index}
            hasDivider={index < lastCheckpointIndex}
            completionStatus={checkpoint.completionStatus}
            hasManagerAccess={hasManagerAccess}
            isCheckpointLocked={checkpoint.locked}
            isCheckpointCompleted={checkpoint.isCompleted}
            onClick={() => openCheckpointDetails(checkpoint._id)}
            isCurrentParticipantCheckpoint={
              checkpoint.isCurrentParticipantCheckpoint
            }
            participantJoinedDate={participantJoinedDate}
          />
        )}
      </InfiniteList>
      {selectedCheckpoint && (
        <SideDrawer
          drawerClassNames="top-[64px] !h-[calc(100vh - 64px)] !shadow-0"
          drawerBackdropClassNames="!backdrop-blur-0 animate-none"
          open
          maxWidth="100%"
          onClose={() => {
            setSelectedCheckpoint(null);
          }}
          animationDirection="bottom"
          noPadding
          customCloseIcon={() => <></>}>
          <ChallengeCheckpointPage
            communityId={communityId}
            challengeId={challengeId}
            checkpointCount={checkpointCount}
            participantId={participantId}
            selectedCheckpointData={selectedCheckpoint}
            closeSideDrawer={() => setSelectedCheckpoint(null)}
            setSelectedCheckpoint={setSelectedCheckpoint}
            onCompleteCheckpoint={(
              updatedCheckpoint: CurrentCheckpointDataType
            ) => {
              updateCompletionStatusWithUpdatedCheckpoint(
                updatedCheckpoint
              );
              completeCheckpoint?.(updatedCheckpoint);
            }}
            isLeaderboardEnabled={isLeaderboardEnabled}
            isFeedEnabled={isFeedEnabled}
            hasChallengeEnded={hasChallengeEnded}
            enableCheckpointSubmissionAfterDeadline={
              enableCheckpointSubmissionAfterDeadline
            }
            onCompleteFeed={onCompleteFeed}
            participantJoinedDate={participantJoinedDate}
          />
        </SideDrawer>
      )}
    </div>
  );
};

export default withComponentClassName(CheckpointListDisplay);
