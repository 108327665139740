import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import Icon from '@/components/npl/Icon';

const ANIMATION_DIRECTION = {
  FROM_BOTTOM: 'bottom',
  FROM_RIGHT: 'right'
};

const animationConfig = {
  duration: 200,
  fill: 'forwards'
};

const fromRightCloseKeyframes = [
  { transform: 'translateX(0)' },
  { transform: 'translateX(100%)' }
];

const fromRightOpenKeyframes = [
  { transform: 'translateX(100%)' },
  { transform: 'translateX(0)' }
];

const fromBottomCloseKeyframes = [
  { transform: 'translateY(0)' },
  { transform: 'translateY(100%)' }
];

const fromBottomOpenKeyframes = [
  { transform: 'translateY(100%)' },
  { transform: 'translateY(0)' }
];

function getInitialHiddenPosition(animationDirection) {
  switch (animationDirection) {
    case ANIMATION_DIRECTION.FROM_BOTTOM:
      return { transform: 'translateY(100%)' };
    case ANIMATION_DIRECTION.FROM_RIGHT:
      return { transform: 'translateX(100%)' };
    default:
      return { transform: 'translateX(100%)' };
  }
}

function getAnimationKeyframesObject(animationDirection) {
  switch (animationDirection) {
    case ANIMATION_DIRECTION.FROM_BOTTOM:
      return {
        openAnimation: fromBottomOpenKeyframes,
        closeAnimation: fromBottomCloseKeyframes
      };
    case ANIMATION_DIRECTION.FROM_RIGHT:
      return {
        openAnimation: fromRightOpenKeyframes,
        closeAnimation: fromRightCloseKeyframes
      };
    default:
      return {
        openAnimation: fromRightOpenKeyframes,
        closeAnimation: fromRightCloseKeyframes
      };
  }
}

const SideDrawer = ({
  onClose,
  open,
  children,
  customCloseIcon,
  drawerClassNames,
  drawerBackdropClassNames = '',
  maxWidth = '800px',
  isEqual = false,
  setIsDiscardChangesModalOpen = false,
  noPadding = false,
  isNotAnimated = false,
  animationDirection = ANIMATION_DIRECTION.FROM_RIGHT
}) => {
  const drawerRef = useRef(null);
  const drawerBackdropRef = useRef(null);
  const animationInfo = getAnimationKeyframesObject(animationDirection);

  const onDrawerClose = () => {
    // allow parent to handle closing sheet
    if (isEqual) {
      return onClose();
    }

    if (isNotAnimated) {
      return onClose();
    }

    if (!drawerRef.current) return;

    const isClosingConfirmation = onClose();

    if (isClosingConfirmation) {
      return;
    }

    const slideInAnimation = drawerRef.current.animate(
      animationInfo.closeAnimation,
      animationConfig
    );
    slideInAnimation.onfinish = onClose;
  };

  useEffect(() => {
    if (drawerRef.current && open) {
      if (isNotAnimated) {
        return;
      }

      drawerRef.current.animate(
        animationInfo.openAnimation,
        animationConfig
      );
    }
  }, [animationInfo.openAnimation, open, isNotAnimated]);

  useEffect(() => {
    if (document.body) {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.removeProperty('overflow');
      }
    }

    return () => {
      if (document.body) {
        document.body.style.removeProperty('overflow');
      }
    };
  }, [open]);

  useEffect(() => {
    const drawerBackdropEle = drawerBackdropRef.current;
    const backdropClickHandler = (e) => {
      if (e.target === drawerBackdropEle) {
        if (isEqual) {
          setIsDiscardChangesModalOpen(true); // Open the modal if isEqual is true
        } else {
          onDrawerClose();
        }
      }
    };
    if (drawerBackdropEle) {
      drawerBackdropEle.addEventListener('click', backdropClickHandler);
    }
    return () => {
      if (drawerBackdropEle) {
        drawerBackdropEle.removeEventListener(
          'click',
          backdropClickHandler
        );
      }
    };
  }, [isEqual, setIsDiscardChangesModalOpen, onClose]);

  return createPortal(
    <div
      ref={drawerBackdropRef}
      className={classNames(
        'h-full-dvh fixed left-0 top-0 z-over-intercom w-screen animate-backdrop',
        open ? 'flex' : 'hidden',
        drawerBackdropClassNames
      )}>
      <div
        ref={drawerRef}
        className={classNames(
          `relative ml-auto w-full max-w-[800px] overflow-auto bg-npl-base-white shadow-npl-styles-shadow-01  ${
            !noPadding && `p-16 md:px-24 md:py-16`
          }`,
          drawerClassNames
        )}
        style={{
          maxWidth,
          ...(isNotAnimated
            ? {}
            : getInitialHiddenPosition(animationDirection))
        }}>
        {!customCloseIcon ? (
          <div className="absolute right-16 top-16 flex justify-end p-8 md:right-24 md:p-12">
            <div role="button" tabIndex={0} onClick={onDrawerClose}>
              <Icon name="x-close" width={18} height={18} />
            </div>
          </div>
        ) : (
          customCloseIcon(onDrawerClose)
        )}
        <div>{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default SideDrawer;
