import React, { FC } from 'react';

import { getTimezoneId } from '@/utility/dateHelper';
import { openInNewTab } from '@/utility/helpers';
import { formatDateTimeWithLocale, t } from '@/utility/localization';

import CalendarDayBox from '@/components/common/CalendarDayBox';
import ClampText from '@/components/common/ClampText';
import CopyIconWithTooltip from '@/components/common/CopyIconWithTooltip';
import { getStartAndEndTimeRange } from '@/components/common/EventDateLocAccessBlock';
import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import NplPopoverMenu from '@/components/common/NPLPopoverMenu/NplPopoverMenu';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';
import NPLIconButton from '@/components/npl/NPLIconButton';

import { CheckpointEventFormData } from '../../types';

type Props = {
  eventData: CheckpointEventFormData;
  handleDeleteEvent: () => void;
  setIsEventFormOpen: (open: boolean) => void;
  isMemberView?: boolean;
};

const CheckpointEventCard: FC<Props> = ({
  eventData,
  setIsEventFormOpen,
  handleDeleteEvent,
  isMemberView = false
}) => {
  const eventStartTime = eventData?.startTime;

  const hasEventStarted = new Date() >= new Date(eventStartTime);

  return (
    <div className="py-20 md:py-24 md:pl-24 md:pr-12 px-16 bg-npl-neutral-light-alpha-2 md:rounded-16">
      <div className="flex">
        <div className="hidden md:block">
          <CalendarDayBox
            date={eventStartTime}
            size="lg"
            accent
            bordered={false}
          />
        </div>

        <div className="flex flex-col gap-8 md:ml-24 flex-grow md:mr-12">
          <div className="text-label-lg font-semibold break-words">
            {eventData.title}
          </div>
          <div className="text-label-md font-medium">
            {`${formatDateTimeWithLocale(eventStartTime, 'eee, dd MMM')}, 
            ${getStartAndEndTimeRange(eventStartTime, eventData?.endTime)}
            ${getTimezoneId()}`}
          </div>
          {eventData?.type === 'live' && (
            <div className="flex gap-8">
              <Icon
                name="video-recorder"
                width={20}
                height={20}
                fill="#1B1B1899"
              />
              <div className="flex flex-col">
                <div className="text-npl-text-icon-on-light-surface-secondary text-body-sm font-medium">
                  {t('online')}
                </div>
                {eventData?.liveLink && (
                  <div className="flex gap-4">
                    <ClampText
                      text={eventData?.liveLink}
                      line={1}
                      collapsedCtaText=""
                      // disableLinkAware
                      linkIsSecondaryColor={true}
                      customClassNames="text-body-xs !text-npl-text-icon-on-light-surface-secondary/65"
                      customCTA={undefined}
                    />
                    <CopyIconWithTooltip
                      textToCopy={eventData?.liveLink}
                      isTertiaryColor
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {eventData?.type === 'inPerson' && (
            <div className="flex gap-8 items-center">
              <Icon
                name="marker-pin-01"
                width={20}
                height={20}
                fill="#1B1B1899"
              />
              {eventData?.inPersonLocation ? (
                <div className="flex gap-4">
                  <ClampText
                    text={eventData?.inPersonLocation}
                    line={1}
                    collapsedCtaText=""
                    // disableLinkAware
                    linkIsSecondaryColor={true}
                    customClassNames="text-body-xs !text-npl-text-icon-on-light-surface-secondary/65"
                    customCTA={undefined}
                  />
                  <CopyIconWithTooltip
                    textToCopy={eventData?.inPersonLocation}
                    isTertiaryColor
                  />
                </div>
              ) : (
                <div className="text-npl-text-icon-on-light-surface-secondary text-body-sm font-medium">
                  {t('in-person')}
                </div>
              )}
            </div>
          )}
        </div>
        {!isMemberView && (
          <div className=" self-center">
            <HeadlessDropdown
              placement="bottom"
              renderField={() => (
                <NPLIconButton
                  hierarchy="plain"
                  size="sm"
                  icon="dots-vertical"
                  onClick={() => {}}
                />
              )}
              renderOptions={({ closeDropdown }) => {
                return (
                  <NplPopoverMenu
                    onClose={closeDropdown}
                    options={[
                      {
                        id: 'edit',
                        title: t('edit'),
                        icon: 'edit',
                        onClick: () => {
                          setIsEventFormOpen(true);
                          closeDropdown();
                        }
                      },
                      {
                        id: 'delete',
                        title: t('delete'),
                        icon: 'trash-01',
                        isDanger: true,
                        onClick: () => {
                          handleDeleteEvent();
                          closeDropdown();
                        }
                      }
                    ]}
                  />
                );
              }}
            />
          </div>
        )}
      </div>
      {isMemberView && eventData?.liveLink && (
        <div className="mt-16">
          <NPLButton
            stretch
            buttonText={t('join')}
            hierarchy="neutral_primary"
            size="lg"
            disabled={!hasEventStarted}
            onClick={() => openInNewTab(eventData?.liveLink)}
          />
          {!hasEventStarted && (
            <div className="mt-8 text-center text-label-md text-npl-text-tertiary-on-light">
              {t('you-can-join-when-its-starting')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckpointEventCard;
