import classNames from 'classnames';
import PropTypes from 'prop-types';

const TOGGLE_SIZES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg'
};

export const TOGGLE_DISPLAY_TYPES = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger',
  disabled: 'disabled',
  darkYellow: 'dark-yellow'
};

const Toggle = ({
  size = TOGGLE_SIZES.sm,
  displayType = TOGGLE_DISPLAY_TYPES.default,
  toggled,
  defaultToggled,
  onChange,
  dataTestId = '',
  customClassNames = '',
  ...props
}) => {
  const getSizeClass = () => {
    switch (size) {
      case TOGGLE_SIZES.md:
        return 'w-56 h-32';
      case TOGGLE_SIZES.sm:
      default:
        return '!w-44 h-24';
    }
  };

  const getDisplayTypeClass = () => {
    switch (displayType) {
      case TOGGLE_DISPLAY_TYPES.primary:
        return 'peer-checked:bg-npl-yellow-light-solid-11';
      case TOGGLE_DISPLAY_TYPES.secondary:
        return 'peer-checked:bg-secondary-500';
      case TOGGLE_DISPLAY_TYPES.success:
        return 'peer-checked:bg-success-500';
      case TOGGLE_DISPLAY_TYPES.danger:
        return 'peer-checked:bg-danger-500';
      case TOGGLE_DISPLAY_TYPES.disabled:
        return '!bg-black/10 cursor-not-allowed';
      case TOGGLE_DISPLAY_TYPES.darkYellow:
        return 'peer-checked:bg-npl-yellow-light-solid-11';
      default:
        return 'peer-checked:bg-npl-neutral-dark-solid-3';
    }
  };

  const getDisabledClasses = () => {
    switch (displayType) {
      case TOGGLE_DISPLAY_TYPES.primary:
        return 'peer-disabled:bg-npl-success-light-6';
    }
  };

  // const getPeerFocusClasses = () => {
  //   return 'peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-npl-neutral-dark-solid-2';
  // };

  const getPeerActiveClasses = () => {
    return 'peer-checked:after:translate-x-full peer-checked:after:border-npl-neutral-dark-solid-3';
  };

  const getAfterElementClasses = () => {
    const positionClasses = 'h-5 w-5';
    const appearanceClasses = 'bg-npl-base-white shadow-sm rounded-full';
    // Appends the after element so that you can write normal tailwind classes
    const positionClassString =
      `${positionClasses} ${appearanceClasses} ${customClassNames}`
        .split(' ')
        .filter((className) => !!className)
        .map((className) => `after:${className}`)
        .join(' ');
    return classNames(
      `after:content-[''] after:absolute after:top-2 after:left-2 after:transition-all after:h-5 after:w-5 after:rounded-full after:shadow-sm`,
      positionClassString,
      {
        'after:bg-npl-neutral-light-solid-3':
          displayType === TOGGLE_DISPLAY_TYPES.disabled,
        'after:bg-npl-base-white':
          displayType != TOGGLE_DISPLAY_TYPES.disabled
      }
    );
  };

  const checked =
    toggled !== undefined
      ? {
          checked: toggled
        }
      : {};

  const defaultChecked =
    defaultToggled !== undefined
      ? {
          defaultChecked: defaultToggled
        }
      : {};

  return (
    <label
      className={classNames(
        'relative inline-flex h-fit w-fit cursor-pointer items-center'
      )}>
      <input
        {...props}
        {...checked}
        {...defaultChecked}
        onChange={onChange}
        data-test-id={`${dataTestId}-checkbox`}
        type="checkbox"
        value=""
        className="peer sr-only"
        disabled={displayType === TOGGLE_DISPLAY_TYPES.disabled}
      />
      <div
        className={classNames(
          `peer rounded-full bg-npl-neutral-light-solid-3 peer-disabled:cursor-not-allowed ${getDisplayTypeClass()}`,
          getSizeClass(),
          getPeerActiveClasses(),
          // getPeerFocusClasses(),
          getAfterElementClasses(),
          getDisabledClasses()
        )}></div>
    </label>
  );
};

Toggle.propTypes = {
  toggled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(Object.values(TOGGLE_SIZES)),
  displayType: PropTypes.oneOf(Object.values(TOGGLE_DISPLAY_TYPES)),
  dataTestId: PropTypes.string,
  customClassNames: PropTypes.string
};

export default Toggle;
