import config from '@/utility/config';

import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';

const { communityApiBasePath } = config;

export const getPresignedUrlOfVideo = async ({
  filename,
  communityId,
  entityId,
  entityType,
  mimeType,
  otherBodyParams
}) => {
  const params = new URLSearchParams({
    originalname: filename,
    entityId,
    entityType,
    mimetype: mimeType,
    ...otherBodyParams
  });

  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/video/community/${communityId}/video-presigned-url?${params.toString()}`
  );
};
