import dynamic from 'next/dynamic';
import { FC } from 'react';

import type { ShakaPlayerType } from './types';

const ShakaPlayer = dynamic(
  () => import('@/components/common/AdaptiveVideo/partials/ShakaPlayer'),
  {
    ssr: false
  }
);

type AdaptiveVideoProps = ShakaPlayerType;

const AdaptiveVideo: FC<AdaptiveVideoProps> = (props) => {
  return <ShakaPlayer {...props} />;
};

export default AdaptiveVideo;
