import config from '@/utility/config';
import { getFormattedStartEndDate } from '@/utility/dateHelper';
import { t } from '@/utility/localization';

import { PARTICIPANT_PROGRAM_ITEM_STATUS } from '@/pages/portal/challenges/constants';

export const getChallengeManagementUrl = ({
  id,
  activeCommunityId = ''
}) => {
  let route = `/portal/challenges/${id}`;
  if (activeCommunityId) {
    route = `${route}?activeCommunityId=${activeCommunityId}`;
  }
  return route;
};

export const getChallengePublicPageUrl = (communitySlug, challengeSlug) =>
  `${
    typeof window !== 'undefined'
      ? window.location.origin
      : config.siteBaseUrl
  }/${communitySlug}/challenges/${challengeSlug}`;

export const getChallengeDurationByCheckpointDuration = (
  checkpointDuration,
  challengeDuration
) => {
  const duration = parseInt(challengeDuration / checkpointDuration, 10);

  if (checkpointDuration === 1) {
    return `${duration} ${t('days')}`;
  }
  if (checkpointDuration === 7) {
    return `${duration} ${t('weeks')}`;
  }
  if (checkpointDuration === 30) {
    return `${duration} ${t('months')}`;
  }
  return `${challengeDuration} ${t('days')}`;
};

export const renderDateString = (challengeInfo) => {
  return `${getChallengeDurationByCheckpointDuration(
    challengeInfo?.challengeSpecific?.checkpointDurationInDays,
    challengeInfo?.durationInDays
  )}, ${getFormattedStartEndDate({
    startDate: new Date(challengeInfo?.startTime),
    endDate: new Date(challengeInfo?.endTime)
  })}`;
};

export const checkIfCheckpointIsCompletedByStatus = (status) => {
  return [
    PARTICIPANT_PROGRAM_ITEM_STATUS.LATE_COMPLETED,
    PARTICIPANT_PROGRAM_ITEM_STATUS.COMPLETED
  ].includes(status);
};
