import React, { FC } from 'react';
import { createPortal } from 'react-dom';

import { copyToClipboard, downloadFile } from '@/utility/helpers';
import { t } from '@/utility/localization';
import { getFilenameAndExtensionFromLink } from '@/utility/stringHelper';

import Icon from '@/components/npl/Icon';
import type { IconName } from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';
import NPLIconButton from '@/components/npl/NPLIconButton';

import useEscapeClick from '@/hooks/useEscapeClick';

type FullScreenedFilePreviewProps = {
  children: React.ReactNode;
  fileLink: string;
  onClose: () => void;
  alternativeThumbnailIcon?: {
    fill: string;
    name: IconName<'common'>;
  };
  thumbnail?: string;
};

const FullScreenedFilePreview: FC<FullScreenedFilePreviewProps> = ({
  fileLink,
  thumbnail,
  alternativeThumbnailIcon, //show icon if `thumbnail` is empty or undefined
  children,
  onClose
}) => {
  useEscapeClick(onClose);

  const { filename } = getFilenameAndExtensionFromLink(fileLink);

  if (typeof window === 'undefined') return null;

  return createPortal(
    <div className="absolute z-over-intercom">
      <div className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur-md" />
      <div className="h-full-dvh fixed inset-0 flex w-screen flex-col">
        {/* Nav */}
        <div className="min-w-0 flex justify-between space-x-16 px-24 py-16">
          <div className="min-w-0 flex items-center gap-8 truncate">
            <NPLIconButton
              darkMode
              size="sm"
              hierarchy="outline"
              icon="arrow-left"
              onClick={onClose}
            />
            {thumbnail ? (
              <img
                src={thumbnail}
                alt="preview-thumbnail"
                className="h-40 w-40 min-w-40 rounded-8 object-cover"
              />
            ) : (
              <div className="flex h-40 w-40 min-w-40 items-center justify-center rounded-8 border-1 border-npl-neutral-light-solid-6 bg-white-default">
                <Icon
                  name={alternativeThumbnailIcon?.name ?? 'file-01'}
                  fill={alternativeThumbnailIcon?.fill ?? '#1b1b18'}
                  width={18}
                  height={18}
                />
              </div>
            )}
            <p className="truncate text-label-md font-medium text-npl-text-primary-on-dark">
              {filename}
            </p>
          </div>
          <div className="flex items-center space-x-6">
            <NPLButton
              multiply
              darkMode
              size="sm"
              hierarchy="outline"
              buttonText={t('download')}
              leadIcon="download-01"
              onClick={() => {
                downloadFile({
                  url: fileLink,
                  fileName: filename
                });
              }}
            />
            <NPLButton
              multiply
              darkMode
              size="sm"
              hierarchy="outline"
              buttonText={t('copy-link')}
              leadIcon="copy"
              onClick={() => {
                copyToClipboard(fileLink);
              }}
            />
          </div>
        </div>
        <div className="flex h-full justify-center">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default FullScreenedFilePreview;
