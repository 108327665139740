export const VIDEOS_TAB = 'videos';
export const RESOURCES_TAB = 'resources';
export const CLASSES_TAB = 'class';
export const RESOURCES_TAB_NEW = 'collection';

export const VIDEO_LIBRARY_CARD_TYPE = 'video';
export const RESOURCE_LIBRARY_CARD_TYPE = 'resource';
export const EXTERNAL_LINK_LIBRARY_CARD_TYPE = 'external_link';
export const FILE_TYPE_LIBRARY_CARD_TYPE = 'file';
export const TOAST_AUTOCLOSE_TIME = 3000;
export const FULL_TEXT_MAX_LINE = 1000;

export const LIBRARY_EMPTY_STATE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/library/png/library-empty-state.png';

// item types
export const FOLDER_ITEM_TYPE_FILE = 'file';
export const FOLDER_ITEM_TYPE_VIDEO = 'video';
export const FOLDER_ITEM_TYPE_AUDIO = 'audio';
export const FOLDER_ITEM_TYPE_LINK = 'external_link';
export const FOLDER_ITEM_TYPE_IMAGE = 'image';
export const FOLDER_ITEM_TYPE_SECTION = 'section';
