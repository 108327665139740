import dynamic from 'next/dynamic';
import { FC, useState } from 'react';

import { openFileSelector } from '@/utility/helpers';
import { t } from '@/utility/localization';
import { getFileNameWithExtensionFromLink } from '@/utility/stringHelper';
import withComponentClassName from '@/utility/withComponentClassName';

import { uploadDocumentWithMultipart } from '@/services/uploadService';

import FullScreenedFilePreview from '../FullScreenedFilePreview';
import FileUploaderInput, {
  FILE_UPLOADER_TYPES
} from '../ImageUploader/FileUploaderInput';
import { showErrorToast } from '../ToastContainer';

const DocumentPreview = dynamic(
  () => import('@/components/common/DocumentPreview'),
  {
    ssr: false
  }
);

type DocumentUploaderProps = {
  onUploaded: ({
    documentLink,
    fileSize
  }: {
    documentLink: string;
    fileSize: number;
  }) => void;
  uploadProps: {
    entityId: string;
    entityType: string;
  };
  value: string;
  disabled?: boolean;
  onCancel?: () => void;
};

const DocumentUploader: FC<DocumentUploaderProps> = ({
  value,
  disabled,
  uploadProps,
  onUploaded,
  onCancel
}) => {
  const { entityId, entityType } = uploadProps;
  const [preview, setPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const accept = '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx';

  const handleSelectFile = async (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];

    const mbSize = 10;
    const fileSizeLimit = 1024 * 1024 * mbSize;
    if (file.size > fileSizeLimit) {
      showErrorToast(
        t('file-size-exceeds-the-limit-of-imagemblimit-mb', {
          imageMBLimit: String(mbSize)
        })
      );
      return;
    }

    setIsUploading(true);
    const { error, data } = await uploadDocumentWithMultipart({
      useCase: entityType,
      associatedEntityId: entityId,
      document: file
    });
    if (error) {
      showErrorToast(error);
    } else {
      onUploaded?.({
        documentLink: data?.docUrl,
        fileSize: file.size
      });
    }
    setIsUploading(false);
  };

  return (
    <>
      <FileUploaderInput
        onClick={() =>
          value
            ? setPreview(true)
            : openFileSelector(accept, handleSelectFile)
        }
        fileValue={value}
        fileName={getFileNameWithExtensionFromLink(value)}
        onCancel={onCancel}
        emptyStateTitle={isUploading ? '' : t('upload-file')}
        emptyStateDescription={isUploading ? t('uploading1') : accept}
        disabled={disabled || isUploading}
        fileType={FILE_UPLOADER_TYPES.FILE}
      />
      {preview && value && (
        <FullScreenedFilePreview
          fileLink={value}
          alternativeThumbnailIcon={{
            name: 'file-attachment-04',
            fill: '#007AA3'
          }}
          onClose={() => setPreview(false)}>
          <div className="mx-24 w-full overflow-auto rounded-12 bg-white-default">
            <DocumentPreview uri={value} />
          </div>
        </FullScreenedFilePreview>
      )}
    </>
  );
};

export default withComponentClassName(DocumentUploader);
