import React from 'react';

import { t } from '@/utility/localization';

import NPLIconButton from '@/components/npl/NPLIconButton';

interface UploadFailedProps {
  onDelete: () => void;
}

export const UploadFailed: React.FC<UploadFailedProps> = ({
  onDelete
}) => {
  return (
    <>
      <div className="flex gap-16 items-center">
        <div className="flex flex-col gap-4">
          <p className="text-body-md font-medium text-npl-text-error-on-light">
            {t('upload-failed')}
          </p>
          <p className="text-label-sm text-npl-text-tertiary-on-light">
            {t('please-delete-and-try-uploading-again')}
          </p>
        </div>
      </div>
      <div className="gap-4 group-hover:flex hidden transition-all">
        <NPLIconButton
          hierarchy="destructive_plain"
          icon="trash-01"
          size="md"
          onClick={onDelete}
        />
      </div>
    </>
  );
};
