import Image from '@/components/common/Image';

import { getThumbnailProps } from '../../utils';

const ResourcePreviewImage = ({ resource }) => {
  return (
    <div className="m-auto w-auto md:max-w-[80vw]">
      <Image
        customClassNames="max-h-[80vh]"
        {...getThumbnailProps(resource?.link)?.thumbnail}
      />
    </div>
  );
};

export default ResourcePreviewImage;
