import React, { useRef } from 'react';

import MoreVerticalIcon from '@/components/common/Icons/MoreVerticalIcon';

import useOutsideComponentClickDetect from '@/hooks/useOutsideComponentClickDetect';

const MenuSelector = ({ showMenu, setShowMenu, children }) => {
  const handleOutsideClick = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };

  const handleShowMenu = (e) => {
    e.stopPropagation();
    setShowMenu(true);
  };

  const selectRef = useRef();
  useOutsideComponentClickDetect(selectRef, handleOutsideClick);
  return (
    <div ref={selectRef} className="flex items-center justify-center">
      <button
        onClick={(e) => handleShowMenu(e)}
        className="flex h-32 w-32 items-center justify-center rounded-full mix-blend-multiply hover:bg-npl-neutral-light-solid-3">
        <MoreVerticalIcon fill="#1B1B18" />
      </button>
      <div className={`absolute ${!showMenu ? 'hidden' : ''} z-[5]`}>
        {children}
      </div>
    </div>
  );
};

export default MenuSelector;
