import classNames from 'classnames';

import NextImage from '@/components/common/NextImage';

import { DEFAULT_IMG_URI_FOR_USER_ON_CHAT } from '@/pages/portal/analytics/constants';

const ParticipantThumbnailStack = ({
  participants,
  showYou = true,
  dark = false,
  size,
  className
}: {
  participants: string[];
  className?: string;
  dark?: boolean;
  showYou?: boolean;
  size?: number;
}) => {
  // clone the array
  const thumbnails = [...participants];
  if (thumbnails.length === 0) {
    return null;
  }
  if (thumbnails.length > 3) {
    // take only the first 3 thumbnails
    thumbnails.splice(3, thumbnails.length - 3);
  }

  //   add last thumbnail "YOU" to the end of the array
  if (showYou) {
    thumbnails.push('YOU');
  }

  return (
    <>
      <div>
        <div
          className="relative flex"
          style={{
            width: size + (thumbnails.length - 1) * size * 0.7,
            height: size
          }}>
          {thumbnails.map((item, idx) => {
            return (
              <div
                key={idx}
                style={{
                  transform: `translateX(${70 * idx}%)`,
                  width: size,
                  height: size
                }}
                className={classNames(
                  'absolute overflow-hidden rounded-full border-2',
                  dark
                    ? 'border-[#212121] bg-opacity-50'
                    : 'border-npl-base-white',
                  className
                )}>
                {item === 'YOU' ? (
                  <div className="flex aspect-square items-center justify-center bg-npl-yellow-light-solid-3 text-overline-xs font-semibold text-npl-yellow-light-solid-11 shadow-npl-styles-shadow-01">
                    YOU
                  </div>
                ) : (
                  <NextImage
                    mobileImgProps={{
                      src: item ?? DEFAULT_IMG_URI_FOR_USER_ON_CHAT,
                      layout: 'fill'
                    }}
                    alt="thumbnail"
                    className="aspect-square overflow-hidden rounded-full"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ParticipantThumbnailStack;
