import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

type BorderedRoundedContainerProps = {
  children: ReactNode;
  customPadding?: string;
  maxWidth?: number;
};

const BorderedRoundedContainer: FC<BorderedRoundedContainerProps> = ({
  children,
  maxWidth,
  customPadding
}) => {
  const getMaxWidthClassName = () => {
    switch (maxWidth) {
      case 640:
        return 'max-w-[640px]';
      default:
        return 'max-w-[640px]';
    }
  };

  return (
    <div
      className={classNames(
        'w-full p-16 md:p-24 bg-white-default border-[0.5px] rounded-16 border-npl-neutral-light-alpha-6 mx-auto',
        getMaxWidthClassName(),
        customPadding
      )}>
      {children}
    </div>
  );
};

export default BorderedRoundedContainer;
