import React from 'react';
import { createPortal } from 'react-dom';

import { t } from '@/utility/localization';
import { getFilenameAndExtensionFromLink } from '@/utility/stringHelper';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import NPLButton from '@/components/npl/NPLButton';

import useEscapeClick from '@/hooks/useEscapeClick';

const ImageSubmissionPreview = ({
  imageFile,
  onCopyClick,
  onDownloadClick,
  onClose
}) => {
  useEscapeClick(onClose);
  const { isGtEqMd } = useWindowWidthContext();
  const { filename } = getFilenameAndExtensionFromLink(imageFile);

  if (typeof window === 'undefined') return null;

  return createPortal(
    <div className="absolute z-over-intercom">
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md" />
      <div className="h-full-dvh fixed inset-0 w-screen">
        {/* Nav */}
        <div className="flex flex-col justify-between gap-16 px-24 py-16 md:flex-row">
          <div className="flex items-center space-x-8">
            <NPLButton
              multiply
              rounded
              darkMode
              leadIcon="arrow-left"
              onClick={onClose}
            />
            <img
              src={imageFile}
              alt="preview-thumbnail"
              className="h-32 w-32 rounded-8 object-cover"
            />
            <p className="text-label-md font-medium text-npl-text-primary-on-dark">
              {filename}
            </p>
          </div>
          <div className="flex space-x-6">
            <NPLButton
              multiply
              darkMode
              size="sm"
              hierarchy="outline"
              buttonText={t('download')}
              leadIcon="download-01"
              stretch={!isGtEqMd}
              onClick={onDownloadClick}
            />
            <NPLButton
              multiply
              darkMode
              size="sm"
              stretch={!isGtEqMd}
              hierarchy="outline"
              buttonText={t('copy-link')}
              leadIcon="copy"
              onClick={onCopyClick}
            />
          </div>
        </div>
        <div className="py-40">
          <img
            src={imageFile}
            alt="preview"
            className="mx-auto max-h-[80vh] max-w-[70vw]"
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ImageSubmissionPreview;
