import classNames from 'classnames';
import React from 'react';

import { t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import Icon from '@/components/npl/Icon';

import NextImage from '../NextImage';

export const FILE_UPLOADER_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  FILE: 'file'
};

function FileUploaderInput({
  onClick,
  fileValue,
  fileName,
  onCancel,
  emptyStateTitle,
  emptyStateDescription,
  disabled,
  uploading = false,
  transcodingProgress = 0,
  fileType
}) {
  const renderThumbnail = () => {
    switch (fileType) {
      case FILE_UPLOADER_TYPES.IMAGE:
        return (
          <NextImage
            className="rounded-4"
            mobileImgProps={{
              src: fileValue,
              width: 24,
              height: 24,
              objectFit: 'cover',
              priority: true,
              quality: 25
            }}
          />
        );
      case FILE_UPLOADER_TYPES.VIDEO:
        return <Icon name="video-recorder" width={16} height={16} />;
      case FILE_UPLOADER_TYPES.FILE:
      default:
        return <Icon name="file-01" width={16} height={16} />;
    }
  };

  const isTranscoding =
    transcodingProgress !== 0 && transcodingProgress !== 100;

  const disabledInput = disabled || uploading || isTranscoding;

  const getInputLabel = () => {
    if (uploading) {
      return t('uploading');
    }
    if (isTranscoding) {
      return t('processing');
    }
    return emptyStateTitle;
  };

  return (
    <div
      className={classNames(
        'flex w-full items-center justify-between rounded-8 bg-npl-neutral-light-solid-2 p-12 outline-1 outline-npl-neutral-light-solid-7 transition-all',
        {
          outline: fileValue,
          'outline-dashed': !fileValue,
          'bg-npl-neutral-light-solid-4': disabled,
          'hover:bg-npl-neutral-light-solid-3 cursor-pointer': !disabled,
          'animate-pulse': uploading || isTranscoding
        }
      )}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }
      }}>
      {fileValue ? (
        <>
          <div className="flex w-full items-center space-x-12 truncate">
            <div className="flex h-24 w-24 items-center justify-center">
              {renderThumbnail()}
            </div>
            <p className="min-w-0 truncate text-body-md">{fileName}</p>
          </div>
          {!disabledInput && (
            <button
              className="flex h-24 w-24 items-center justify-center"
              onClick={(e) => {
                if (disabledInput) {
                  return;
                }
                e.stopPropagation();
                onCancel();
              }}>
              <Icon name="x-circle" width={16} height={16} />
            </button>
          )}
        </>
      ) : (
        <div
          className={classNames(
            'flex w-full flex-wrap items-center justify-center gap-8'
          )}>
          <div className="flex h-24 items-center justify-center gap-8">
            <Icon
              name="upload-cloud-01"
              width={20}
              height={20}
              fill="#1B1B18A6"
            />
            {/* TODO @TRENT, check with guang on uploading design */}
            <div className="text-label-sm">{getInputLabel()}</div>
          </div>
          <div className="text-label-sm text-npl-text-icon-on-light-surface-tertiary">
            {isTranscoding && !uploading
              ? `${Math.floor(transcodingProgress)}%`
              : emptyStateDescription}
          </div>
        </div>
      )}
    </div>
  );
}

export default withComponentClassName(FileUploaderInput);
