import classNames from 'classnames';
import { addDays, isFuture, isSameYear } from 'date-fns';
import React from 'react';

import { formatDateTimeWithLocale, t } from '@/utility/localization';

import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { PARTICIPANT_PROGRAM_ITEM_STATUS } from '@/pages/portal/challenges/constants';

export const CheckpointPreviewCardSkeleton = () => {
  return (
    <div className="flex gap-x-16">
      <div className="flex h-40 w-40 items-center justify-center rounded-8 border bg-npl-neutral-light-solid-3 border-npl-neutral-light-solid-3">
        <Icon
          name="lock-03"
          className="fill-npl-text-tertiary-on-light"
          width={24}
          height={24}
        />
      </div>
      <div>
        <div className="text-label-lg font-medium text-npl-text-icon-on-light-surface-primary">
          <div className="h-[20px] w-[100px] animate-pulse rounded-8 bg-npl-neutral-light-solid-3"></div>
        </div>
        <div>
          <div className="mt-4 h-[16px] w-[200px] animate-pulse rounded-8 bg-npl-neutral-light-solid-3"></div>
        </div>
      </div>
    </div>
  );
};

const CheckpointPreviewCard = ({
  checkpoint,
  hasDivider = false,
  isBlurDivider = false,
  hasManagerAccess = false,
  completionStatus = null,
  isCheckpointLocked = true,
  isCheckpointCompleted = false,
  isCurrentParticipantCheckpoint = false,
  participantJoinedDate = null,
  onClick = null,
  isPreview = false
}) => {
  const { endTime, title, unlockAfterXDays } = checkpoint;
  let { startTime } = checkpoint;

  if (!startTime && !isPreview) {
    startTime = addDays(
      participantJoinedDate ?? new Date(),
      unlockAfterXDays ?? 0
    );
  }

  const isLateCheckpointSubmission =
    completionStatus === PARTICIPANT_PROGRAM_ITEM_STATUS.LATE_COMPLETED;

  const isClickable =
    (!isCheckpointLocked || hasManagerAccess) && Boolean(onClick);

  const formattedStartTime = formatDateTimeWithLocale(
    new Date(startTime),
    isSameYear(new Date(), new Date(startTime))
      ? 'dd MMM, hh:mm a'
      : 'dd MMM yyyy, hh:mm a'
  );

  const isSubmittable = !isCheckpointLocked && !isCheckpointCompleted;

  return (
    <div
      className={classNames('group relative flex gap-x-16', {
        'cursor-pointer': isClickable
      })}
      onClick={() => {
        if (isClickable) {
          onClick();
        }
      }}>
      <div className="h-full">
        <div
          className={classNames(
            'flex h-40 w-40 items-center justify-center rounded-8 border',
            {
              'bg-white-default border-npl-separator-alpha': isSubmittable,
              'bg-npl-neutral-light-solid-3 border-npl-neutral-light-solid-3':
                isCheckpointLocked,
              'bg-npl-success-light-3 border-npl-success-light-6':
                isCheckpointCompleted && !isCheckpointLocked,
              'bg-white-default border-npl-yellow-light-alpha-6':
                isCurrentParticipantCheckpoint && isSubmittable
            }
          )}>
          {isCheckpointLocked && (
            <Icon
              name="lock-03"
              className="fill-npl-text-tertiary-on-light"
              width={24}
              height={24}
            />
          )}
          {!isCheckpointLocked && !isCheckpointCompleted && (
            <Icon
              name="check"
              className="fill-npl-text-disabled-on-light"
              width={24}
              height={24}
            />
          )}
          {isCheckpointCompleted && !isCheckpointLocked && (
            <div className="flex h-full w-full flex-col items-center justify-center">
              <Icon
                name="check"
                className="fill-npl-success-light-11"
                width={24}
                height={24}
              />
              {isLateCheckpointSubmission && (
                <span className="mt-[-6px] text-label-sm font-medium text-npl-success-light-11">
                  {t('late')}
                </span>
              )}
            </div>
          )}
        </div>
        {hasDivider && (
          <div
            className={classNames(
              'absolute bottom-0 top-40 mx-20 h-full border-l-2',
              {
                'border-dashed border-npl-neutral-light-solid-6':
                  isCheckpointLocked,
                'border-npl-success-light-6':
                  isCheckpointCompleted && !isCheckpointLocked,
                'border-npl-separator-alpha':
                  !isCheckpointLocked && !isCheckpointCompleted
              }
            )}>
            {isBlurDivider && (
              <div className="bg-gradient-to-b h-18 w-18 translate-x-[-10px] translate-y-[100%] from-transparent to-white-default"></div>
            )}
          </div>
        )}
      </div>
      <div className={classNames(isBlurDivider ? 'pb-48' : 'pb-32')}>
        <div className="w-full min-h-[40px] flex flex-col justify-center">
          <div
            className={classNames(
              'text-label-lg font-medium',
              isCheckpointLocked
                ? 'text-npl-text-icon-on-light-surface-secondary'
                : 'text-npl-text-icon-on-light-surface-primary'
            )}>
            {title}
          </div>
          {startTime && (
            <p
              suppressHydrationWarning
              className="mt-4 text-left text-label-sm text-npl-text-icon-on-light-surface-secondary">
              {!isPreview && isFuture(new Date(startTime))
                ? t('unlocks-at', {
                    date: formattedStartTime
                  })
                : formattedStartTime}
            </p>
          )}
          {!startTime &&
            Object.prototype.hasOwnProperty.call(
              checkpoint,
              'unlockAfterXDays'
            ) && (
              <p
                suppressHydrationWarning
                className="mt-4 text-left text-label-sm text-npl-text-icon-on-light-surface-secondary">
                {checkpoint?.unlockAfterXDays > 0
                  ? `${t('unlocks-after')} ${checkpoint?.unlockAfterXDays} ${checkpoint?.unlockAfterXDays > 1 ? t('days') : t('day')}`
                  : t('unlocks-immediately')}
              </p>
            )}
          {new Date(startTime) <= new Date() &&
            new Date(endTime) > new Date() &&
            !isCheckpointCompleted && (
              <p className="mt-4 text-npl-yellow-light-alpha-11 text-label-sm">
                {t('happening-now')}
              </p>
            )}
        </div>
      </div>
      {isClickable && (
        <div className="ml-auto">
          {isSubmittable ? (
            <NPLButton
              buttonText={t('start')}
              size="sm"
              hierarchy="neutral_primary"
            />
          ) : (
            <NPLButton
              buttonText={t('view')}
              size="sm"
              hierarchy="neutral_secondary"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CheckpointPreviewCard;
