import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

import { t } from '@/utility/localization';

import MenuSelector from '@/features/Announcements/MenuSelector';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import LinkAwareText from '@/components/common/LinkAwareText';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { showToast } from '@/components/common/ToastContainer';
import Icon from '@/components/npl/Icon';

import { ReadMoreSingleLine } from '@/pages/portal/products/components/ReadMoreSingleLine/ReadMoreSingleLine';
import { copyTextToClipboard } from '@/pages/portal/utils/events';

import {
  FOLDER_ITEM_TYPE_AUDIO,
  FOLDER_ITEM_TYPE_IMAGE,
  FOLDER_ITEM_TYPE_VIDEO
} from '../../constants';
import ResourcePreviewAudio from './ResourcePreviewAudio';
import ResourcePreviewImage from './ResourcePreviewImage';
import ResourcePreviewVideo from './ResourcePreviewVideo';

const ResourcePreviewModal = ({ resource, onClose }) => {
  const { isGtEqMd } = useWindowWidthContext();
  const [showMenu, setShowMenu] = useState(false);
  const { title, description, thumbnail, menuOptionsFns, copyLinkUrl } =
    resource;

  const onCopyShareUrl = (text) => {
    copyTextToClipboard(text);
    showToast({
      text: t('copied-to-clipboard')
    });
  };

  const imgProps = thumbnail
    ? {
        alt: thumbnail.alt,
        mobileImgProps: {
          src: thumbnail.mobileImgData?.src,
          ...thumbnail.meta,
          width: 32,
          height: 32
        }
      }
    : null;

  const renderHeader = useCallback(() => {
    return (
      <div className="to-npl-transparent-25 bg-gradient-to-b flex w-full flex-row justify-between from-black via-npl-transparent-black-75 p-16 md:px-24">
        <div className="flex w-full max-w-[632px] flex-row items-center gap-8">
          <button onClick={onClose} className="p-8">
            <Icon name="arrow-left" fill="#f5f5f5" />
          </button>
          {imgProps && (
            <div className="min-w-32 min-h-32 max-w-32 max-h-32">
              <NextImage
                className="h-32 w-32 overflow-hidden rounded-8 border-1 border-solid border-npl-neutral-light-solid-6 object-cover"
                {...imgProps}
              />
            </div>
          )}

          <div className="ml-4 mr-16 flex w-full flex-col overflow-hidden">
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-label-lg font-medium text-npl-text-icon-on-dark-primary">
              {title}
            </div>
            <ReadMoreSingleLine maxHeight={'20px'}>
              <div
                className={`text-body-sm text-npl-text-icon-on-dark-secondary`}>
                <LinkAwareText text={description} />
              </div>
            </ReadMoreSingleLine>
          </div>
        </div>
        <div className="flex flex-row items-start gap-8 py-4">
          {menuOptionsFns &&
            (isGtEqMd ? (
              <>
                {menuOptionsFns?.download && (
                  <RoundedButton
                    displayType="outline"
                    size={'sm'}
                    onClick={(e) => menuOptionsFns?.download(e)}>
                    <span className="flex flex-row items-center text-button-md text-npl-text-icon-on-dark-primary">
                      <Icon
                        name="download-01"
                        className="mr-12"
                        fill="#f5f5f5"
                        width="18"
                        height="18"
                        fillOpacity="0.65"
                      />
                      {t('download')}
                    </span>
                  </RoundedButton>
                )}
                {copyLinkUrl && (
                  <RoundedButton
                    displayType={'outline'}
                    size={'sm'}
                    onClick={() => onCopyShareUrl({ text: copyLinkUrl })}>
                    <span className="flex flex-row items-center whitespace-nowrap text-button-md text-npl-text-icon-on-dark-primary ">
                      <Icon
                        name="copy"
                        className="mr-12"
                        fill="#f5f5f5"
                        fillOpacity="0.65"
                        width="18"
                        height="18"
                      />
                      {t('copy-link')}
                    </span>
                  </RoundedButton>
                )}
              </>
            ) : (
              <MenuSelector
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                darkMode>
                <div
                  className={`relative right-[100px] top-[20px] flex select-none flex-col overflow-hidden whitespace-pre rounded-8 bg-white-default drop-shadow-md`}>
                  {copyLinkUrl && (
                    <button
                      onClick={() => onCopyShareUrl({ text: copyLinkUrl })}
                      className="flex flex-row whitespace-pre px-8 py-12 text-left font-poppins text-label-md text-npl-text-icon-on-light-surface-primary hover:bg-neutral-95">
                      <Icon
                        name="copy"
                        className="mr-12"
                        fill="#f5f5f5"
                        fillOpacity="0.65"
                        width="18"
                        height="18"
                      />
                      {t('copy-link')}
                    </button>
                  )}
                  {menuOptionsFns?.download && (
                    <button
                      onClick={(e) => menuOptionsFns?.download(e)}
                      className="text-color flex flex-row px-8 py-12 text-left font-poppins text-label-md text-npl-text-icon-on-light-surface-primary hover:bg-neutral-95">
                      <Icon
                        name="download-01"
                        className="mr-12"
                        fill="#f5f5f5"
                      />
                      {t('download')}
                    </button>
                  )}
                </div>
              </MenuSelector>
            ))}
        </div>
      </div>
    );
  }, [resource, isGtEqMd, showMenu, menuOptionsFns, onClose]);

  const renderContent = useCallback(() => {
    switch (resource?.type) {
      // case FOLDER_ITEM_TYPE_FILE:
      //   return <ResourcePreviewPDF resource={resource} />;
      case FOLDER_ITEM_TYPE_IMAGE:
        return <ResourcePreviewImage resource={resource} />;
      case FOLDER_ITEM_TYPE_AUDIO:
        return <ResourcePreviewAudio resource={resource} />;
      case FOLDER_ITEM_TYPE_VIDEO:
        return <ResourcePreviewVideo video={resource?.video} />;
      default:
        onClose();
        return null;
    }
  }, [resource, resource?.video, isGtEqMd]);

  if (typeof window === 'undefined') return null;

  return createPortal(
    <div className="fixed bottom-0 left-0 right-0 top-0 z-over-intercom flex h-full w-full flex-col bg-npl-transparent-black-75 backdrop-blur">
      {renderHeader()}
      <div className="max-h-[80dvh] flex h-full w-full flex-col items-center justify-center">
        {renderContent()}
      </div>
    </div>,
    document.body
  );
};

export default ResourcePreviewModal;
