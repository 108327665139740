import { useEffect, useState } from 'react';

import { t } from '@/utility/localization';

import Editor from '@/components/common/Editor/Editor';
import NextImage from '@/components/common/NextImage';

const AboutChallenge = ({
  hostProfileImage,
  hostName,
  description,
  descriptionHTML = null
}) => {
  const [descriptionState, setDescriptionState] = useState(null);
  useEffect(() => {
    setDescriptionState(description);
  }, [description]);

  return (
    <div className="mx-auto flex flex-col justify-between gap-x-80 gap-y-24 py-40 md:flex-row md:pb-20">
      <div className="flex w-fit min-w-[240px] max-w-[280px] gap-x-12">
        {hostProfileImage && (
          <NextImage
            mobileImgProps={{
              src: hostProfileImage,
              width: 50,
              height: 50
            }}
            className="h-40 w-40 rounded-full"
            alt="host profile image"
          />
        )}

        <div className="space-y-4 text-label-md font-medium">
          <div className="w-fit text-npl-text-icon-on-light-surface-secondary">
            {t('challenge-by')}
          </div>
          <div className="flex w-fit items-end text-label-lg text-npl-text-icon-on-light-surface-primary">
            {hostName}
          </div>
        </div>
      </div>
      <div className="w-full flex-1">
        {(descriptionState || descriptionHTML) && (
          <Editor
            contentHTML={descriptionHTML}
            existingContent={description}
            editable={false}
            customContentClasses="w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
          />
        )}
      </div>
    </div>
  );
};

export default AboutChallenge;
