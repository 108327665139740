import style from './AdaptiveVideoModal.module.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useEscapeClick from '@/hooks/useEscapeClick';

import AdaptiveVideo from '../AdaptiveVideo';
import Modal from '../Modal';

const AdaptiveVideoModal = ({
  isDesktopView,
  adaptiveVideoData,
  customClassName,
  onModalClose,
  onVideoEnd,
  isVideoPlaying,
  showCloseIcon
}) => {
  useEscapeClick(onModalClose);
  const returnVideoContainerClassName = () => {
    //add desktop square class if it is required in the future
    const isMobileVideoSquareOriented =
      adaptiveVideoData?.mobileVideoOrientation === 'square';
    const isMobileVideoPortraitOriented =
      adaptiveVideoData?.mobileVideoOrientation === 'portrait';
    const isLgVideoPortraitOriented =
      adaptiveVideoData?.lgVideoOrientation === 'portrait';

    const trailerModalContainerClassName = classNames({
      'preview-modal-square': isMobileVideoSquareOriented,
      'preview-modal-portrait': isMobileVideoPortraitOriented,
      'preview-modal':
        !isMobileVideoSquareOriented && !isMobileVideoPortraitOriented
    });

    return isDesktopView
      ? classNames({
          'preview-modal-portrait': isLgVideoPortraitOriented,
          'preview-modal': !isLgVideoPortraitOriented
        })
      : trailerModalContainerClassName;
  };

  const modalContainerClassName = classNames(
    style[returnVideoContainerClassName()],
    customClassName
  );

  if (!isVideoPlaying) {
    return null;
  }

  return (
    <Modal
      open={isVideoPlaying}
      onClose={onModalClose}
      showCloseIcon={showCloseIcon}
      customContainerClass={modalContainerClassName}
      customInnerClass="!p-0 !overflow-auto"
      modalContainerClassName="!max-h-[100vh] top-0 left-0"
      // Added backdrop blur
      customBackdropClassNames="!backdrop-blur-lg !bg-dark-20">
      <AdaptiveVideo
        {...adaptiveVideoData}
        isTrailerPlaying={isVideoPlaying}
        onVideoEnd={onVideoEnd}
      />
    </Modal>
  );
};

AdaptiveVideoModal.propTypes = {
  isDesktopView: PropTypes.bool,
  customClassName: PropTypes.string,
  onModalClose: PropTypes.func,
  onVideoEnd: PropTypes.func,
  isVideoPlaying: PropTypes.bool,
  showCloseIcon: PropTypes.bool
};

export default AdaptiveVideoModal;
