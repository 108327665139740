import classNames from 'classnames';
import { addDays } from 'date-fns';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

import { formatNumber } from '@/utility/helpers';
import { t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Editor from '@/components/common/Editor/Editor';
import { isEditorEmpty } from '@/components/common/Editor/utils/common';
import PopOverModal from '@/components/common/PopOverModal';
import {
  showErrorToast,
  showSuccessToast
} from '@/components/common/ToastContainer';
import BorderedRoundedContainer from '@/components/containers/BorderedRoundedContainer';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import ResourcePreviewModal from '@/pages/member/products/components/ResourcePreviewModal/ResourcePreviewModal';
import AttachmentRow from '@/pages/portal/challenges/[challengeId]/components/CheckpointSideDrawer/components/AttachmentsUpload/AttachmentRow';
import CheckpointEventCard from '@/pages/portal/challenges/[challengeId]/components/CheckpointSideDrawer/components/EventUpload/CheckpointEventCard';
import { VideoPreview } from '@/pages/portal/challenges/[challengeId]/components/CheckpointSideDrawer/components/VideoUpload/VideoPreview';
import {
  CHECKPOINT_ACTION_TYPE,
  CHECKPOINT_SUBMISSION_STATUS_TYPES
} from '@/pages/portal/challenges/constants';
import ConfirmationModal from '@/pages/portal/events/components/ConfirmationModal';

import CheckpointPageFooter from './components/CheckpointPage/CheckpointPageFooter';
import CheckpointSubmissionForm from './components/CheckpointPage/CheckpointSubmissionForm';
import FeedFormScreen from './components/CheckpointPage/FeedFormScreen';
import useCheckpointSubmissionForm from './hooks/useCheckpointSubmissionForm';

const CheckpointSuccessScreen = dynamic(
  () => import('./components/CheckpointPage/CheckpointSuccessScreen'),
  {
    ssr: false
  }
);

function ChallengeCheckpointPage({
  communityId,
  challengeId,
  participantId,
  selectedCheckpointData,
  closeSideDrawer,
  onCompleteCheckpoint,
  setSelectedCheckpoint,
  isLeaderboardEnabled,
  isFeedEnabled,
  hasChallengeEnded,
  enableCheckpointSubmissionAfterDeadline,
  onCompleteFeed,
  isPreview = false,
  checkpointCount,
  participantJoinedDate
}) {
  const [isSuccessScreenOpen, setIsSuccessScreenOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFeedFormOpen, setIsFeedFormOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [resourceToPreview, setResourceToPreview] = useState(null);
  const { submission, completionStatus, checkpointData, leaderboardInfo } =
    selectedCheckpointData || {};

  const { isGtEqMd } = useWindowWidthContext();
  const {
    _id: checkpointId,
    title,
    submissionRequired,
    description,
    coverVideo,
    completedProfilePics,
    completedCount,
    submissionQuestions,
    event,
    attachmentItems,
    unlockAfterXDays
  } = checkpointData || selectedCheckpointData || {};
  let { startTime } = checkpointData || selectedCheckpointData || {};

  const pointsEarned =
    selectedCheckpointData?.leaderboardInfo?.pointsEarned ??
    selectedCheckpointData?.checkpointLeaderboardInfo?.pointsEarned ??
    0;

  const {
    handleUpdateParticipantCheckpoint,
    openSubmissionForm,
    isSubmissionFormOpen,
    resetStateAndCloseForm,
    handleInputChange,
    formData,
    setSubmissionFormState,
    submissionFormState,
    initializeFormData,
    isUpdatingSubmission,
    inputError,
    checkForChangesInQuestions,
    currentSubmissionQuestions,
    isSubmissionChanged,
    feedFormData,
    setFeedFormData,
    submitFeedForm
  } = useCheckpointSubmissionForm({
    communityId,
    challengeId,
    participantId,
    checkpointId,
    submissionQuestions,
    submittedAnswers: submission?.answers,
    onCompleteCheckpoint,
    setSelectedCheckpoint,
    isFeedEnabled,
    isPreview
  });
  const videoData = coverVideo?.mediaData?.video;

  const handleSaveDraft = async () => {
    const { error } = await handleUpdateParticipantCheckpoint(
      CHECKPOINT_ACTION_TYPE.DRAFT
    );
    if (error) {
      showErrorToast(error);
      return;
    }
    showSuccessToast(t('saved-as-draft'));
    // Needed to close popover
    setShowConfirmation(false);
  };
  const hasContent =
    videoData ||
    !isEditorEmpty(description) ||
    event ||
    attachmentItems?.length > 0;

  // Hide if submission is open and if theres no description/video or its in mobile view
  const hideContentAndDescription =
    isSubmissionFormOpen && (!hasContent || !isGtEqMd);

  const isCheckpointCompleted = [
    CHECKPOINT_SUBMISSION_STATUS_TYPES.COMPLETED,
    CHECKPOINT_SUBMISSION_STATUS_TYPES.LATE_COMPLETED
  ].includes(completionStatus);

  const isParticipant = Boolean(participantId);

  if (!startTime) {
    startTime = addDays(
      participantJoinedDate ?? new Date(),
      unlockAfterXDays ?? 0
    );
  }

  // if start time is less than or equal to current date, means challenge started
  const hasCheckpointStarted = new Date(startTime) <= new Date();

  const renderCheckpointFooter = () => (
    <div
      className={classNames('sticky left-0 right-0', {
        'bottom-0': isPreview,
        'bottom-[64px]': !isPreview
      })}>
      <CheckpointPageFooter
        completedProfilePics={completedProfilePics}
        isUploading={isUploading}
        completedCount={completedCount}
        isCheckpointCompleted={isCheckpointCompleted}
        isLeaderboardEnabled={isLeaderboardEnabled}
        submissionRequired={submissionRequired}
        showSuccessScreen={() => setIsSuccessScreenOpen(true)}
        handleCompleteCheckpoint={handleUpdateParticipantCheckpoint}
        openSubmissionForm={openSubmissionForm}
        isSubmissionFormOpen={isSubmissionFormOpen}
        completionStatus={completionStatus}
        setSubmissionFormState={setSubmissionFormState}
        submissionFormState={submissionFormState}
        initializeFormData={initializeFormData}
        isUpdatingSubmission={isUpdatingSubmission}
        checkForChangesInQuestions={checkForChangesInQuestions}
        hasCheckpointStarted={hasCheckpointStarted}
        isParticipant={isParticipant}
        canSubmitAfterDeadline={enableCheckpointSubmissionAfterDeadline}
        hasChallengeEnded={hasChallengeEnded}
        isPreview={isPreview}
      />
    </div>
  );

  const isSubmissionFormInMobileView = !isGtEqMd && isSubmissionFormOpen;
  const showConfirmationPopOver =
    isSubmissionFormInMobileView && showConfirmation;
  const showConfirmationModal = isGtEqMd && showConfirmation;

  function checkCanCloseSubmissionForm(onClose) {
    const isChanged = isSubmissionChanged();
    if (isChanged) {
      setShowConfirmation(true);
    } else {
      setShowConfirmation(false);
      onClose && onClose();
    }
  }

  // Show confirmation prompt if edits are made, otherwise just close
  function handleCloseSubmissionForm() {
    checkCanCloseSubmissionForm(resetStateAndCloseForm);
  }

  function handleCloseSideDrawer() {
    checkCanCloseSubmissionForm(closeSideDrawer);
  }

  function handleDiscardChanges() {
    resetStateAndCloseForm();
    setShowConfirmation(false);
  }

  const confirmationText = isCheckpointCompleted
    ? t('continue-to-edit')
    : t('save-as-draft');

  function handleContinueToEdit() {
    setShowConfirmation(false);
  }

  const confirmationAction = isCheckpointCompleted
    ? handleContinueToEdit
    : handleSaveDraft;

  const modalPopOverSubText = isCheckpointCompleted
    ? t('you-have-unsaved-changes-are-you-sure-you-want-to-leave')
    : t('save-your-submission-as-a-draft-or-exit-without-saving');

  function renderConfirmationPopover() {
    return (
      <div className="flex flex-col justify-center gap-10 px-16 pb-32 text-center font-poppins">
        <h3 className="text-20 font-semibold text-npl-text-icon-on-light-surface-primary">
          {t('there-are-unsaved-changes')}
        </h3>
        <p className="pb-24 text-16">{modalPopOverSubText}</p>
        <NPLButton
          hierarchy="accent_primary"
          buttonText={confirmationText}
          size="lg"
          onClick={confirmationAction}
        />
        <NPLButton
          hierarchy="outline"
          buttonText={t('discard-and-exit')}
          size="lg"
          onClick={handleDiscardChanges}
        />
      </div>
    );
  }

  const isDefaultThumbnail =
    coverVideo?.mediaData?.thumbnail ===
    coverVideo?.mediaData?.video?.thumbnailLink;

  const heightOfChallengeCheckpointPageClassName = isPreview
    ? 'h-[calc(100vh-64px)]'
    : 'h-screen';

  return (
    <>
      <div
        className={classNames(
          'relative flex flex-col',
          heightOfChallengeCheckpointPageClassName
        )}>
        <div className="absolute left-16 top-16">
          {!isPreview && (
            <NPLButton
              rounded
              hierarchy="outline"
              leadIcon="chevron-left"
              size="sm"
              disabled={isUploading}
              onClick={
                isSubmissionFormInMobileView
                  ? handleCloseSubmissionForm
                  : handleCloseSideDrawer
              }
            />
          )}
        </div>
        <div
          className={classNames('grid', {
            'grid-cols-1 overflow-hidden': !isSubmissionFormOpen,
            'grid-cols-2 gap-4':
              isSubmissionFormOpen && !hideContentAndDescription
          })}>
          {!hideContentAndDescription && (
            <div
              className={classNames(
                'mx-auto flex overflow-y-auto w-full grid-cols-1 flex-col py-24 ',
                {
                  'border-r-1 border-npl-neutral-light-solid-4':
                    isSubmissionFormOpen
                },
                heightOfChallengeCheckpointPageClassName
              )}>
              {isPreview ? (
                <div className="mx-auto mb-24 w-full max-w-[560px] gap-4">
                  <div className="max-w-[300px] lg:max-w-[560px] text-label-lg font-semibold">
                    <div className="text-label-md text-npl-text-secondary-on-light px-24 md:px-0">
                      {t('checkpoint-number-of-total-checkpoints', {
                        checkpointNumber: checkpointData?.index,
                        totalCheckpoints: checkpointCount
                      })}
                    </div>
                    <div className="flex flex-row items-center gap-4 text-heading-md font-semibold px-24 md:px-0">
                      {title}
                      {isCheckpointCompleted && (
                        <Icon
                          name="check-circle-filled"
                          width={16}
                          height={16}
                          fill="#30A66D"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mx-auto mb-24 flex flex-col items-center justify-center gap-4">
                  <div className="max-w-[300px] lg:max-w-[560px] flex flex-row items-center gap-4 text-center text-label-lg font-semibold">
                    {title}
                    {isCheckpointCompleted && (
                      <Icon
                        name="check-circle-filled"
                        width={16}
                        height={16}
                        fill="#30A66D"
                      />
                    )}
                  </div>

                  {isCheckpointCompleted && isLeaderboardEnabled && (
                    <div className="text-label-sm lowercase text-npl-text-icon-on-light-surface-secondary">
                      {`${formatNumber(pointsEarned)} ${t('points')}`}
                    </div>
                  )}
                </div>
              )}

              <div className="mx-auto w-full flex-1 overflow-y-auto md:px-40 px-24 pb-120">
                {hasContent ? (
                  <>
                    {videoData && (
                      <div className="w-full max-w-[560px] mx-auto">
                        <VideoPreview
                          video={videoData}
                          stretch
                          customThumbnail={
                            coverVideo?.mediaData?.thumbnail
                          }
                          isDefaultThumbnail={isDefaultThumbnail}
                        />
                      </div>
                    )}
                    {event && Object.keys(event).length > 0 && (
                      <div className="max-w-[560px] mx-auto">
                        <CheckpointEventCard
                          eventData={event}
                          isMemberView
                        />
                      </div>
                    )}
                    <div className="mb-[200px]">
                      {!isEditorEmpty(description) && (
                        <div className="mt-24 md:p-0">
                          <Editor
                            existingContent={description}
                            editable={false}
                            customContentClasses="max-w-[560px] mx-auto w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
                          />
                        </div>
                      )}
                      {attachmentItems?.length > 0 && (
                        <div className="max-w-[560px] mx-auto mt-24 ">
                          <BorderedRoundedContainer customPadding="pb-12">
                            <div className="flex justify-between items-center">
                              <div className="text-label-lg font-semibold">
                                {t('attachments')}
                              </div>
                            </div>
                            {attachmentItems?.map((attachment, index) => (
                              <AttachmentRow
                                key={attachment?._id}
                                attachment={attachment}
                                onClick={() => {
                                  if (attachment?.link) {
                                    window.open(
                                      attachment.link,
                                      '_blank',
                                      'noopener,noreferrer'
                                    );
                                  } else {
                                    setResourceToPreview(attachment);
                                  }
                                }}
                                isMemberView
                                isNotLastItem={
                                  index !== attachmentItems.length - 1
                                }
                              />
                            ))}
                          </BorderedRoundedContainer>
                        </div>
                      )}
                    </div>
                    {resourceToPreview && (
                      <ResourcePreviewModal
                        resource={resourceToPreview}
                        onClose={() => setResourceToPreview(null)}
                      />
                    )}
                  </>
                ) : (
                  <div className="mt-120 flex flex-grow flex-col items-center justify-center gap-8 px-24">
                    <Icon
                      name="grid-01"
                      width={40}
                      height={40}
                      fill="#1B1B1880"
                    />
                    <div className="text-center text-heading-xs font-medium text-npl-text-icon-on-light-surface-secondary">
                      {isCheckpointCompleted
                        ? t('this-checkpoint-has-no-additional-content')
                        : t(
                            'this-checkpoint-has-no-additional-content-complete-it-now'
                          )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {(isSubmissionFormOpen || isPreview) && (
            <div
              className={classNames(
                'flex flex-col overflow-y-auto',
                heightOfChallengeCheckpointPageClassName
              )}>
              <div className="flex flex-1  flex-shrink grid-cols-1 justify-center pb-[100px]">
                <CheckpointSubmissionForm
                  isUpdatingSubmission={isUpdatingSubmission}
                  handleSaveDraft={handleSaveDraft}
                  closeSubmissionForm={handleCloseSubmissionForm}
                  handleInputChange={handleInputChange}
                  formData={formData}
                  submissionQuestions={currentSubmissionQuestions}
                  hideContentAndDescription={hideContentAndDescription}
                  submissionFormState={submissionFormState}
                  communityId={communityId}
                  checkpointData={checkpointData}
                  participantId={participantId}
                  inputError={inputError}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  hasCheckpointStarted={hasCheckpointStarted}
                  isPreview={isPreview}
                />
              </div>
              {renderCheckpointFooter()}
            </div>
          )}
          {!isSubmissionFormOpen && renderCheckpointFooter()}
        </div>
      </div>
      {isFeedFormOpen && (
        <FeedFormScreen
          feedFormData={feedFormData}
          setFeedFormData={setFeedFormData}
          checkpointTitle={title}
          submissionRequired={submissionRequired}
          onSubmit={async () => {
            const { error } = await submitFeedForm();

            if (error) {
              showErrorToast(error);
            }

            setIsFeedFormOpen(false);

            onCompleteFeed?.();

            closeSideDrawer();
          }}
        />
      )}
      {isSuccessScreenOpen && (
        <CheckpointSuccessScreen
          closeScreen={() => {
            setIsSuccessScreenOpen(false);
          }}
          closeCallback={() => {
            //if the feed setting is enabled, we need to show another form for feed data, so we cannot close the side drawer
            if (isFeedEnabled) {
              setIsFeedFormOpen(true);
            } else {
              handleCloseSideDrawer();
            }
          }}
          title={t('title-completed', { title })}
          leaderboardInfo={leaderboardInfo}
          autoClosingTimeout={isFeedEnabled ? 3000 : 0} //3 seconds
          isCloseCtaShown={!isFeedEnabled}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          secondaryBtnText={confirmationText}
          primaryBtnText={t('discard-and-exit')}
          title={t('there-are-unsaved-changes')}
          subtitle={modalPopOverSubText}
          onClose={() => setShowConfirmation(false)}
          primaryBtnOnClick={handleDiscardChanges}
          secondaryBtnOnClick={confirmationAction}
        />
      )}
      {showConfirmationPopOver && (
        <PopOverModal open={showConfirmationPopOver} showCloseIcon={false}>
          {renderConfirmationPopover()}
        </PopOverModal>
      )}
    </>
  );
}

export default withComponentClassName(ChallengeCheckpointPage);
