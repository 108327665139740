import React from 'react';

import { t } from '@/utility/localization';

import ProgressBar from '@/components/common/ProgressBar';
import NPLIconButton from '@/components/npl/NPLIconButton';

interface VideoProcessingProps {
  onDelete: () => void;
  processingPercentComplete: number | null;
}

export const VideoProcessing: React.FC<VideoProcessingProps> = ({
  processingPercentComplete,
  onDelete
}) => {
  return (
    <>
      <div className="w-full">
        <p className="text-body-md font-medium">{t('processing-video')}</p>
        <p className="mt-4 text-label-sm text-npl-text-icon-on-light-surface-secondary">
          {t(
            'this-may-take-a-few-minutes-save-the-checkpoint-and-come-back-later'
          )}
        </p>
        <div className="mt-8">
          {processingPercentComplete ? (
            <div className="flex items-center space-x-8">
              <ProgressBar
                value={Math.trunc(processingPercentComplete)}
                className="h-8 w-full !bg-npl-neutral-light-solid-5"
                filledColorClassName="bg-npl-neutral-light-solid-9"
                darkMode
              />
              <p className="text-label-sm font-medium">
                {`${Math.trunc(processingPercentComplete)}%`}
              </p>
            </div>
          ) : (
            <div className="h-8 w-full overflow-hidden rounded-12">
              <div className="h-8 w-[200%] animate-infinite-scroll bg-striped"></div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-shrink justify-end">
        <NPLIconButton
          hierarchy="plain"
          icon="x-circle"
          size="md"
          onClick={onDelete}
        />
      </div>
    </>
  );
};
