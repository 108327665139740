import React from 'react';

import { t } from '@/utility/localization';

import ProgressBar from '@/components/common/ProgressBar';
import NPLIconButton from '@/components/npl/NPLIconButton';

interface VideoUploadingProps {
  onDelete: () => void;
  roundedProcessingPercentComplete: number;
}

export const VideoUploading: React.FC<VideoUploadingProps> = ({
  roundedProcessingPercentComplete,
  onDelete
}) => {
  return (
    <>
      <div className="w-full">
        <p className="text-body-md font-medium">{t('uploading')}...</p>
        <div className="mt-8">
          <div className="flex items-center space-x-8">
            <ProgressBar
              value={roundedProcessingPercentComplete}
              className="h-8 w-full !bg-npl-neutral-light-solid-5"
              filledColorClassName="bg-npl-neutral-light-solid-9"
              darkMode
            />
            <p className="text-label-sm font-medium">
              {`${roundedProcessingPercentComplete}%`}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink justify-end">
        <NPLIconButton
          hierarchy="plain"
          icon="x-circle"
          size="md"
          onClick={onDelete}
        />
      </div>
    </>
  );
};
