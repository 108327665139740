import AdaptiveVideo from '@/components/common/AdaptiveVideo';

type ResourcePreviewVideoProps = {
  video: {
    hlsLink: string;
    link: string;
  };
};

const ResourcePreviewVideo: React.FC<ResourcePreviewVideoProps> = ({
  video
}) => {
  return (
    <div className="overflow-hidden max-h-[90dvh] max-w-[100dvw] md:max-w-[60dvw] rounded-12">
      <AdaptiveVideo
        videoAssets={{
          hls: video?.hlsLink,
          mp4: video?.link
        }}
        isTrailerPlaying={true}
      />
    </div>
  );
};

export default ResourcePreviewVideo;
