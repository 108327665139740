import { useEffect, useState } from 'react';

import { t } from '@/utility/localization';

import {
  addFeed,
  updateParticipantCheckpoint
} from '@/services/community/CommunityChallengesService';
import { getAxiosErrorMsg } from '@/services/helpers';

import { showErrorToast } from '@/components/common/ToastContainer';

import {
  CHECKPOINT_QUESTION_TYPE,
  SUBMISSION_FORM_STATES
} from '@/pages/portal/challenges/constants';
import { CHECKPOINT_ACTION_TYPE } from '@/pages/portal/challenges/constants';

const DEFAULT_FEED_FORM_DATA = {
  caption: '',
  includeSubmissionInFeed: false
};

function useCheckpointSubmissionForm({
  communityId,
  challengeId,
  participantId,
  checkpointId,
  submissionQuestions,
  submittedAnswers,
  onCompleteCheckpoint,
  setSelectedCheckpoint,
  isFeedEnabled,
  isPreview
}) {
  const [feedFormData, setFeedFormData] = useState({
    ...DEFAULT_FEED_FORM_DATA
  });
  const [currentFeedEntryId, setCurrentFeedEntryId] = useState(null);
  const [isSubmissionFormOpen, setIsSubmissionFormOpen] = useState(
    isPreview && submissionQuestions?.length > 0
  );
  const [formData, setFormData] = useState({});
  const [submissionFormState, setSubmissionFormState] = useState(false);
  const [isUpdatingSubmission, setIsUpdatingSubmission] = useState(false);
  const [inputError, setInputError] = useState([]);
  const [currentSubmissionQuestions, setCurrentSubmissionQuestions] =
    useState(submissionQuestions);

  const isSubmissionChanged = () => {
    // compare to check if diff

    /**
     * 1. submittedAnswer == null: If no user actions, formdata will be an empty object. can close
     * 2. submittedAnswer == null: User types something but remove. formData will show as answer empty string. can close
     */

    // case 1
    if (!submittedAnswers && !formData) {
      return false;
    }

    const isChanged = Object.values(formData)?.some((qna, i) => {
      // Text
      if (qna?.type === CHECKPOINT_QUESTION_TYPE.TEXT) {
        return !submittedAnswers
          ? qna?.answer?.length > 0 // case 2
          : qna?.answer !== submittedAnswers[i]?.answer?.answerText;
      }
      // Image, Video
      return (
        submittedAnswers &&
        qna?.answer?.answerFile !== submittedAnswers[i]?.answer?.answerFile
      );
    });
    return isChanged;
  };

  const checkForChangesInQuestions = () => {
    submittedAnswers?.forEach((submittedObject, index) => {
      const isQuestionTypeTheSame =
        submittedObject?.question?.type ===
        submissionQuestions[index]?.type;

      // If question types are not the same, clear the answer
      // If question types are the same, keep current answer
      if (!isQuestionTypeTheSame) {
        setFormData((prev) => ({
          ...prev,
          [index]: null
        }));
      }
    });

    // Update the question texts, add questions, or remove questions
    setCurrentSubmissionQuestions(submissionQuestions);
  };

  const openSubmissionForm = (formState) => {
    setIsSubmissionFormOpen(true);
    setSubmissionFormState(formState);
    // Already submitted - show submitted answers and (potentially stale) questions
    // Still in draft - show submitted answers and updated questions
    if (formState !== SUBMISSION_FORM_STATES.VIEWING) {
      checkForChangesInQuestions();
    }
  };

  const getSubmissionObject = (actionType) => {
    let hasError = false;
    const submissionObject = [];

    submissionQuestions?.forEach((question, index) => {
      const { answer, type } = formData?.[index] || {};
      // only set errors if actionType is not drafting
      if (!answer && actionType !== CHECKPOINT_ACTION_TYPE.DRAFT) {
        setInputError((prev) => ({
          ...prev,
          [index]: t('this-field-is-required')
        }));
        hasError = true;
        return;
      }

      let answerObject;
      switch (type) {
        case CHECKPOINT_QUESTION_TYPE.TEXT:
          answerObject = {
            question,
            answer: {
              answerText: answer
            }
          };
          break;
        case CHECKPOINT_QUESTION_TYPE.IMAGE:
        case CHECKPOINT_QUESTION_TYPE.VIDEO:
        case CHECKPOINT_QUESTION_TYPE.DOCUMENT:
          answerObject = {
            question,
            answer: answer
          };
          break;
        default:
          answerObject = { question };
          break;
      }

      submissionObject.push(answerObject);
    });

    if (hasError) {
      showErrorToast(
        t('there-are-incomplete-fields-please-check-and-re-submit')
      );
      return null;
    }

    return submissionObject;
  };

  const submitFeedForm = async () => {
    const requestBody = {
      communityId,
      challengeId,
      entryId: currentFeedEntryId,
      requestBody: {
        ...feedFormData
      }
    };

    const { error } = await addFeed(requestBody);

    if (error) {
      showErrorToast(error);
    }

    return { error };
  };

  const handleUpdateParticipantCheckpoint = async (actionType) => {
    // reset inputError every time this gets called again
    setInputError([]);
    const submission = getSubmissionObject(actionType);

    if (!submission) {
      return {
        error: true
      };
    }

    const requestBody = {
      communityId,
      challengeId,
      participantId,
      checkpointId,
      bodyParams: {
        submission
      }
    };

    if (isFeedEnabled && actionType === CHECKPOINT_ACTION_TYPE.COMPLETE) {
      requestBody.bodyParams = {
        ...requestBody.bodyParams,
        ...feedFormData,
        action: actionType
      };
    } else {
      requestBody.bodyParams.action = actionType;
    }

    setIsUpdatingSubmission(true);
    const { data, error } = await updateParticipantCheckpoint(requestBody);
    if (error) {
      const errorMessage = getAxiosErrorMsg(error);
      showErrorToast(getAxiosErrorMsg(errorMessage));
      setIsUpdatingSubmission(false);

      return { data: null, error: errorMessage };
    }

    if (data?.data?.feedEntryId) {
      setCurrentFeedEntryId(data?.data?.feedEntryId);
    }

    if (actionType === CHECKPOINT_ACTION_TYPE.COMPLETE) {
      onCompleteCheckpoint?.(data?.data);
    }
    // Update selected checkpoint
    setSelectedCheckpoint((prev) => ({
      ...prev,
      submission: data?.data?.submission,
      leaderboardInfo: data?.data?.leaderboardInfo
    }));

    setIsUpdatingSubmission(false);
    return { data, error: null };
  };

  const handleInputChange = ({ index, answer, type }) => {
    setFormData((prev) => {
      return {
        ...prev,
        [index]: { answer, type }
      };
    });
  };

  const initializeFormData = () => {
    // There are some cases where we directly edit user's questions
    // This resets the form questions back to what the user have submitted if there was a difference
    if (!submittedAnswers) {
      setFormData({});
    }
    if (submittedAnswers) {
      const initialSubmittedQuestions = submittedAnswers?.map(
        (submittedAnswer) => {
          return submittedAnswer.question;
        }
      );
      setCurrentSubmissionQuestions(initialSubmittedQuestions);
    }

    // reset error states
    setInputError([]);
    submittedAnswers?.map((draft, index) => {
      switch (draft?.question?.type) {
        case CHECKPOINT_QUESTION_TYPE.TEXT:
          handleInputChange({
            index,
            answer: draft?.answer?.answerText,
            type: CHECKPOINT_QUESTION_TYPE.TEXT
          });
          return;
        case CHECKPOINT_QUESTION_TYPE.IMAGE:
        case CHECKPOINT_QUESTION_TYPE.VIDEO:
        case CHECKPOINT_QUESTION_TYPE.DOCUMENT:
          handleInputChange({
            index,
            answer: draft?.answer,
            type: draft?.question?.type
          });
          return;
      }
    });
  };

  const resetStateAndCloseForm = () => {
    // reset all states regarding submissionForm
    setSubmissionFormState('');
    setInputError([]);
    setIsSubmissionFormOpen(false);
    setFeedFormData({ ...DEFAULT_FEED_FORM_DATA });
    initializeFormData();
  };

  useEffect(() => {
    initializeFormData();
  }, [submittedAnswers]);

  return {
    handleUpdateParticipantCheckpoint,
    openSubmissionForm,
    resetStateAndCloseForm,
    isSubmissionFormOpen,
    handleInputChange,
    formData,
    submissionFormState,
    setSubmissionFormState,
    initializeFormData,
    isUpdatingSubmission,
    inputError,
    checkForChangesInQuestions,
    currentSubmissionQuestions,
    isSubmissionChanged,
    setFeedFormData,
    feedFormData,
    submitFeedForm
  };
}

export default useCheckpointSubmissionForm;
