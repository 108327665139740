import ReactPlayer from 'react-player';

const ResourcePreviewAudio = ({ resource }) => {
  return (
    <div className="m-auto w-full max-w-[800px]">
      <div className="relative pt-[10%]">
        <ReactPlayer
          className="absolute left-0 top-0"
          url={resource?.link}
          width="100%"
          height="100%"
          playing
          controls
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload'
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default ResourcePreviewAudio;
