import { useState } from 'react';

import { copyToClipboard } from '@/utility/helpers';
import { t } from '@/utility/localization';
import { getFileNameWithExtensionFromLink } from '@/utility/stringHelper';

import Button from '@/components/common/Button';
import ByUserUpload from '@/components/common/Form/AdvancedImageUpload/ByUserUpload';
import Icon from '@/components/npl/Icon';
import NPLModal from '@/components/npl/NPLModal/NPLModal';

import ImageSubmissionPreview from '@/pages/portal/challenges/[challengeId]/components/ParticipantsTab/components/ParticipantDetailsSideDrawer/ActivityCard/ImageSubmissionPreview';
import { download } from '@/pages/portal/products/utils';

import { showToast } from '../ToastContainer';
import FileUploaderInput, {
  FILE_UPLOADER_TYPES
} from './FileUploaderInput';

/**
 * Advanced image upload component that supports default gallery and custom user upload
 * @param uploadHint The hint to be displayed about the image size or dimension
 * @param defaultImages The list of default images that can be chosen by the user
 * @param value The currently selected image
 * @param previewSize The preview size of the image
 * @param onInput Triggered when user picked an image from the default gallery or uploading their own file
 * @param bucket The target bucket to upload the file to
 * @param path The target path to upload the file to
 * @returns {JSX.Element}
 * @constructor
 */

const ImageUploader = ({
  uploadHint,
  useCase,
  value,
  onCancel,
  onInput,
  disabled
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const pickInput = (image, fullImageData) => {
    setIsModalVisible(false);
    if (onInput) {
      onInput(image, fullImageData);
    }
  };

  const onCopyClick = () => {
    copyToClipboard(value);
    showToast({ text: t('copied') });
  };
  const filenameWithExtension = getFileNameWithExtensionFromLink(value);

  const onDownloadClick = () => {
    download(t, value, filenameWithExtension);
  };

  return (
    <div>
      <FileUploaderInput
        onClick={() =>
          value ? setShowPreview(true) : setIsModalVisible(true)
        }
        fileValue={value}
        fileName={filenameWithExtension ?? ''}
        onCancel={onCancel}
        emptyStateTitle={t('upload-photo')}
        disabled={disabled}
        fileType={FILE_UPLOADER_TYPES.IMAGE}
      />
      {showPreview && (
        <ImageSubmissionPreview
          imageFile={value}
          onCopyClick={onCopyClick}
          onDownloadClick={onDownloadClick}
          onClose={() => setShowPreview(false)}
        />
      )}
      {isModalVisible && (
        <NPLModal
          size="md-550"
          open={isModalVisible}
          showCloseIcon={false}
          onClose={() => setIsModalVisible(false)}
          closeOnBackdropClick={true}>
          <div>
            <Button
              displayType="flat"
              customClassNames="absolute top-16 right-16 hover:bg-neutral-90 !p-8 !rounded-full"
              type="button"
              onClick={() => setIsModalVisible(false)}>
              <Icon name="close-01" fillOpacity={0.65} />
            </Button>

            <div className="mt-24">
              <ByUserUpload
                useCase={useCase}
                onPickImage={pickInput}
                uploadHint={uploadHint}
              />
            </div>
          </div>
        </NPLModal>
      )}
    </div>
  );
};

export default ImageUploader;
