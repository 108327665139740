import { trackGAEvent } from '@/utility/analytics';

const useChallengeLandingPageAnalytics = (
  communityInfo,
  challengeInfo
) => {
  const trackJoinChallengeBtnClick = (access) => {
    trackGAEvent('challenge_landing_page_join_btn_click', {
      communityId: communityInfo?._id,
      communityCode: communityInfo?.code,
      challengeId: challengeInfo._id,
      productId: challengeInfo?._id,
      productType: 'CHALLENGE',
      challengeTitle: challengeInfo.title,
      access: access
    });
  };

  const trackReadMoreBtnClick = (section) => {
    trackGAEvent('challenge_landing_page_read_more_btn_click', {
      challengeId: challengeInfo?._id,
      challengeTitle: challengeInfo?.title,
      productId: challengeInfo?._id,
      productType: 'CHALLENGE',
      section: section
    });
  };
  return {
    trackJoinChallengeBtnClick,
    trackReadMoreBtnClick
  };
};

export default useChallengeLandingPageAnalytics;
