import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

import { trackGAEvent } from '@/utility/analytics';

export const getVideoDuration = (file: File): Promise<number> => {
  return new Promise<number>((resolve) => {
    const fileURL = URL.createObjectURL(file);
    const videoElement = document.createElement('video');
    videoElement.src = fileURL;
    videoElement.onloadedmetadata = function () {
      window.URL.revokeObjectURL(videoElement.src);
      const duration = videoElement.duration;
      resolve(duration);
    };
  });
};

export const compressVideo = async (
  file: File,
  updateProgress: ({
    progress,
    time
  }: {
    progress: number;
    time: number;
  }) => void,
  mimeType = 'video/mp4'
): Promise<Blob> => {
  const startTime = performance.now();
  const ffmpeg = new FFmpeg();

  ffmpeg.on('progress', updateProgress);
  await ffmpeg.load();

  const storedFile = await fetchFile(file);

  await ffmpeg.writeFile(file.name, storedFile);

  const updatedFileName = new Date().getTime() + '.mp4';

  await ffmpeg.exec(['-i', file.name, updatedFileName]);

  const data = (await ffmpeg.readFile(updatedFileName)) as Uint8Array;

  const compressedVideo = new Blob([data.buffer], {
    type: mimeType
  });

  const updatedFile = new File([compressedVideo], file.name, {
    type: mimeType
  });

  const endTime = performance.now();
  const elapsedTime = endTime - startTime;

  // Convert elapsed time to minutes and seconds
  const totalSeconds = Math.floor(elapsedTime / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const elapsedTimeInMinutesAndSeconds = `${minutes}m${seconds}s`;

  trackGAEvent('checkpoint_video_compression_time', {
    timeTaken: elapsedTimeInMinutesAndSeconds,
    fileSize: file.size
  });

  return updatedFile;
};
